.popup {
    width: 100%;
    height: 100%;
    padding: 5.5rem 0;
    overflow-y: auto;
    @extend %reset;
    @extend %noscroll;
    @extend %col;

    @include media(0, $mediaM) {
        padding: 9rem 0 4rem 0;
    }

    &__inner {
        // height: 73.5rem;
        position: relative;
        width: 72rem;
        margin: auto;
        @extend %reset;

        @include media(0, $mediaM) {
            width: 92vw;
            margin: 0 auto;
        }

        &Box {
            position: relative;
            z-index: 1;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 4rem 5rem;
            overflow: hidden;
            background: #f7a600;
            border-radius: 4.5rem;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                padding: 3rem 1.8rem 4rem 1.8rem;
                border-radius: 2rem;
            }
        }
    }

    &._specialPrizeInfoPopup &__inner {
        margin: auto;
    }

    &._specialPrizeInfoPopup &__innerBox {
        padding: 3rem 1.8rem 3rem 1.8rem;
    }

    &._orderPopup &__inner,
    &._gamePopup &__inner,
    &._soundsPopup &__inner {
        width: 68rem;

        @include media(0, $mediaM) {
            width: 92vw;
        }
    }

    &__close {
        position: absolute;
        top: 0;
        left: 100%;
        width: 7.5rem;
        height: 7.5rem;
        margin: 0 0 0rem 1rem;
        background: #fff;
        border-radius: 2.6rem;
        transition: $trans;
        @extend %reset;
        @extend %click;
        @extend %col;
        @include button(0.96);

        @include media(0, $mediaM) {
            top: auto;
            right: 0;
            bottom: 100%;
            left: auto;
            width: 5.5rem;
            height: 5.5rem;
            margin: 0 0 1rem 0;
            border-radius: 1.5rem;
        }

        &:hover {
            background: #ffd600;
        }

        &Icon {
            display: block;
            width: 2.2rem;
            height: 2.2rem;
            margin: auto;
            @extend %reset;
            @include icon(#5c0f8b);

            @include media(0, $mediaM) {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }

    &__blocks {
        position: relative;
        width: 100%;
        transition: $trans;
        @extend %reset;
    }

    &__block {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translate(0, -50%);
        transition: $trans;
        @extend %reset;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-10rem, -50%);
            }

            &._next {
                transform: translate(10rem, -50%);
            }
        }
    }

    &__blocks._static &__block {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__head {
        align-items: center;
        width: 100%;
        margin-bottom: 4rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            margin-bottom: 2.5rem;
        }

        &Title {
            color: #5c0f8b;
            font-size: 4rem;
            font-family: "Meloriac";
            line-height: 1.3;
            text-align: center;

            @include media(0, $mediaM) {
                font-size: 3.1rem;
                line-height: 1.1;
            }
        }

        &Description {
            width: 100%;
            margin-top: 1rem;
            color: #fff;
            font-weight: 500;
            font-size: 1.9rem;
            line-height: 1.4;
            text-align: center;
            text-transform: uppercase;
            @extend %reset;

            @include media(0, $mediaM) {
                margin-top: 1rem;
                font-size: 2.1rem;
            }

            &._purple {
                color: #5c0f8b;
                font-weight: 400;
                text-transform: none;
            }
        }

        &Account {
            margin-top: 1.5rem;
            padding: 1rem 1.6rem;
            color: #5c0f8b;
            font-weight: 500;
            font-size: 2.4rem;
            background: #ffd600;
            border-radius: 1rem;
            @extend %reset;
        }
    }

    &__content {
        align-items: center;
        width: 100%;
        @extend %reset;
        @extend %col;

        &._minHeight {
            justify-content: center;
            min-height: 25rem;
        }
    }

    &__fields {
        align-items: center;
        width: 100%;
        margin-bottom: 3rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            margin-bottom: 2.5rem;
        }
    }

    &__field {
        width: 100%;
        @extend %reset;
        @extend %col;

        &._checkbox {
            width: auto;
        }

        & + & {
            margin-top: 3rem;

            @include media(0, $mediaM) {
                margin-top: 1.8rem;
            }
        }

        &Link {
            margin: 1rem 0 0 auto;
            color: $colorDark;
            font-weight: 500;
            font-size: 1.9rem;
            border-bottom: 1px solid rgba($colorDark, 0.5);
            @extend %reset;
            @extend %click;

            @include media(0, $mediaM) {
                margin-top: 1.3rem;
                font-size: 2.1rem;
            }
        }
    }

    &__socials {
        align-items: center;
        width: 100%;
        margin-bottom: 3rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            width: 80%;
            margin-bottom: 2rem;
        }

        &Support {
            text-transform: uppercase;
            @extend .field__support;

            @include media(0, $mediaM) {
                margin-bottom: 2rem;
            }
        }

        &Items {
            align-items: center;
            justify-content: center;
            width: 100%;
            @extend %reset;
            @extend %row;

            @include media(0, $mediaM) {
                flex-wrap: wrap;
            }
        }

        &Item {
            width: 5.8rem;
            height: 5.8rem;
            padding: 0.2rem 1rem 0 1rem;
            color: $colorDark;
            font-weight: 500;
            font-size: 1.9rem;
            text-transform: uppercase;
            background: #fff;
            border-radius: 1.2rem;
            @extend %reset;
            @extend %click;
            @include button(0.98);

            @include media(0, $mediaM) {
                width: 6.5rem;
                height: 6.5rem;
                margin-bottom: 0.8rem;
                font-size: 2.2rem;
            }

            & + & {
                margin-left: 1rem;
            }

            &Inner {
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                transition: $trans;
                @extend %row;

                &:hover {
                    opacity: 0.5;
                }
            }

            &Icon {
                width: 2.8rem;
                height: 2.8rem;
                margin-top: -0.3rem;
                @extend %reset;

                @include media(0, $mediaM) {
                    width: 2.8rem;
                    height: 2.8rem;
                }
            }
        }
    }

    &__codes {
        align-items: center;
        width: 100%;
        @extend %reset;
        @extend %col;

        &Description {
            align-items: center;
            max-width: 70%;
            margin-bottom: 3rem;
            color: $colorDark;
            font-weight: 500;
            font-size: 1.9rem;
            line-height: 1.4;
            text-align: center;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                max-width: 100%;
                font-size: 2.3rem;
            }

            & span {
                display: block;
                margin: 1.5rem 0;
                padding: 1.3rem 2rem;
                color: $colorDark;
                font-weight: 500;
                font-size: 2.3rem;
                background: #ffd600;
                border-radius: 1rem;
                @extend %reset;
            }

            & a {
                color: #5c0f8b;
                border-bottom: 1px solid rgba(#5c0f8b, 0.6);
                @extend %reset;
                @extend %click;
            }
        }

        &Inputs {
            position: relative;
            z-index: 1;
            margin-bottom: 2rem;
            @extend %reset;

            &Loader {
                z-index: 10;
                will-change: transform;
                @extend %reset;
                @extend %loader;

                &:not(._show) {
                    transform: scale(1.03);
                }

                &Item {
                    @extend %reset;
                    @extend %loaderItem;
                    @include loaderColor($colorMain);
                }
            }

            &Inner {
                align-items: center;
                transition: $trans;
                will-change: transform;
                @extend %reset;
                @extend %row;
            }

            &._loading &Inner {
                transform: scale(0.97);
            }
        }

        &Inputs._loading &Input {
            opacity: 0.5;
            pointer-events: none;
        }

        &Input {
            width: 7.3rem;
            height: 7.3rem;
            margin: 0 0.5rem;
            color: $colorDark;
            font-weight: 500;
            font-size: 2.7rem;
            text-align: center;
            background: #fff;
            border: 0.3rem solid rgba(#ffd600, 0.4);
            border-radius: 2rem;
            transition: $trans;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 6.8rem;
                height: 6.8rem;
                margin: 0 0.4rem;
                font-size: 2.8rem;
                border-width: 0.5rem;
                border-radius: 2rem;
            }
        }
    }

    &__scan {
        position: relative;
        z-index: 1;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 23rem;
        background: #ffd600;
        border-radius: 1.5rem;
        @extend %reset;
        @extend %click;
        @extend %col;
        @include button(0.98);

        &Back {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            @extend %reset;

            & .dashedBorder__rect {
                stroke: rgba(#fff, 0.5);
            }
        }

        &:hover .dashedBorder__rect {
            animation: runStroke 7s infinite linear;
        }

        &Icon {
            width: 4.5rem;
            height: 4.5rem;
            margin-bottom: 2rem;
            @extend %reset;
            @include icon(#5c0f8b);

            @include media(0, $mediaM) {
                width: 6rem;
                height: 6rem;
            }
        }

        &Description {
            max-width: 100%;
            color: #5c0f8b;
            font-weight: 500;
            font-size: 1.9rem;
            line-height: 1.5;
            text-align: center;
            text-transform: uppercase;
            @extend %reset;
        }
    }

    &__qrError {
        align-items: center;
        width: 100%;
        padding: 2rem 0 4rem 0;
        @extend %reset;
        @extend %col;

        &Title {
            max-width: 100%;
            margin-bottom: 1.5rem;
            color: $colorDark;
            font-weight: 500;
            font-size: 2.1rem;
            @extend %reset;
        }

        &Content {
            @extend %reset;
        }

        &Button {
            height: 6.5rem;
            @extend %reset;
        }
    }

    &__qrArea {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 40rem;
        margin-bottom: 2rem;
        overflow: hidden;
        background: #ffd600;
        border-radius: 1.5rem;
        @extend %reset;

        @include media(0, $mediaM) {
            margin-bottom: 0;
        }

        &Loader {
            @extend %reset;
            @extend %loader;

            &Item {
                width: 4rem;
                height: 4rem;
                @extend %reset;
                @extend %loaderItem;
                @include loaderColor($colorDark);
            }
        }

        & canvas {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @extend %reset;
        }
    }

    &__form {
        width: 100%;
        padding-bottom: 1rem;
        @extend %reset;

        &Fields {
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            @extend %reset;
            @extend %row;
        }

        &Field {
            width: calc(50% - 1.2rem);
            margin-bottom: 2.4rem;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 100%;
            }

            &._file {
                width: 100%;
            }

            &Support {
                font-size: 2rem;
                @extend .field__support;

                @include media(0, $mediaM) {
                    font-size: 2.3rem;
                }
            }

            &Content {
                justify-content: space-between;
                width: 100%;
                height: 6rem;
                @extend %reset;
                @extend %row;

                @include media(0, $mediaM) {
                    height: 6.5rem;
                }
            }

            &._file &Content {
                height: auto;
            }

            &Select {
                width: calc(50% - 0.7rem);
                height: 100%;
                @extend %reset;
            }
        }
    }

    &__foot {
        align-items: center;
        width: 100%;
        margin-top: auto;
        @extend %reset;
        @extend %col;
    }

    &__error {
        @extend %reset;

        &._parent {
            max-width: 100%;
            transition: $trans;

            &:not(._empty) {
                margin-top: 2rem;
            }

            &._static {
                @extend %col;
            }
        }
    }

    &__buttons {
        align-items: center;
        justify-content: center;
        width: 100%;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            flex-direction: column-reverse;
            justify-content: flex-start;
        }

        &:not(._child) {
            margin-top: 3rem;
        }
    }

    &__buttons._dynamic &__button {
        width: auto !important;
        white-space: nowrap;
    }

    &__buttons._static &__button {
        width: auto !important;
    }

    &__button {
        width: calc(50% - 1.5rem);
        height: 7.5rem;
        @extend %reset;

        @include media(0, $mediaM) {
            width: 100%;
            height: 8.5rem;
        }

        &._height {
            height: 10.5rem;

            @include media(0, $mediaM) {
                height: auto;
            }
        }

        &._auto {
            width: auto;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &:not(._auto) .button {
            padding-right: 0.5rem;
            padding-left: 0.5rem;

            @include media(0, $mediaM) {
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }

        &._fix {
            @include media(0, $mediaM) {
                width: 70%;
            }
        }

        & + & {
            margin-left: 1.5rem;

            @include media(0, $mediaM) {
                margin-bottom: 2.3rem;
                margin-left: 0;
            }
        }
    }

    &__success {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 23rem;
        margin: 2rem 0 3rem 0;
        padding: 3rem 5rem;
        background: #ffd600;
        border: 0.4rem solid rgba(#fff, 0.4);
        border-radius: 2.5rem;
        @extend %reset;
        @extend %col;

        &Description {
            max-width: 100%;
            color: $colorDark;
            font-weight: 500;
            font-size: 2rem;
            line-height: 1.3;
            text-align: center;
            @extend %reset;

            &._title {
                margin-bottom: 2rem;
                padding: 1.2rem 2rem 1rem 2rem;
                color: #fff;
                font-weight: 500;
                font-size: 2.5rem;
                text-transform: uppercase;
                background: #50ae30;
                border-radius: 1.3rem;
            }
        }
    }

    &__order {
        align-items: center;
        width: 100%;
        margin: -1rem 0 2.5rem 0;
        padding: 4rem 5rem 2rem 5rem;
        background: #ffd100;
        border-radius: 3rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            padding: 2rem 3rem;
            border-radius: 2.5rem;
        }

        &Content {
            position: relative;
            width: 30rem;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 33rem;
            }

            &Inner {
                position: relative;
                z-index: 1;
                width: 100%;
                height: 30rem;
                overflow: hidden;
                background: #fff;
                border: 0.4rem solid $colorDark;
                border-radius: 3rem;
                @extend %reset;

                @include media(0, $mediaM) {
                    height: 33rem;
                    border-radius: 4rem;
                }
            }
        }

        &Arrow {
            position: absolute;
            top: 50%;
            z-index: 2;
            width: 6rem;
            height: 6rem;
            @extend %reset;

            &._prev {
                left: 0;
                transform: translate(-50%, -50%);
            }

            &._next {
                right: 0;
                transform: translate(50%, -50%);
            }
        }

        &Items {
            display: inline-flex;
            height: 100%;
            @extend %reset;
        }

        &Item {
            align-items: center;
            width: calc(30rem - 0.8rem);
            height: 100%;
            padding: 3rem;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                width: calc(33rem - 0.8rem);
            }

            &Image {
                width: 100%;
                height: 20rem;
                object-fit: contain;
                @extend %reset;

                @include media(0, $mediaM) {
                    height: 22rem;
                }
            }

            &Name {
                max-width: 100%;
                color: $colorDark;
                font-weight: 500;
                font-size: 2.3rem;
                text-align: center;
                @extend %reset;
            }
        }

        &Info {
            position: absolute;
            bottom: 0;
            left: 50%;
            z-index: 2;
            padding: 1.2rem 2rem;
            background: #ed40a9;
            border: 0.4rem solid $colorDark;
            border-radius: 36rem;
            transform: translate(-50%, 50%);
            @extend %reset;

            &Inner {
                color: #fff;
                font-weight: 500;
                font-size: 2rem;
                white-space: nowrap;
                @extend %reset;
            }
        }

        &Checkbox {
            margin-top: 4.5rem;
            @extend %reset;
        }

        &Success {
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 3.5rem 0;
            padding: 2.5rem 3rem;
            color: #fff;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 1.2;
            text-align: center;
            background: #ed40a9;
            border: 0.4rem solid $colorDark;
            border-radius: 3rem;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                margin: 6rem 0;
            }
        }
    }

    &__code {
        align-items: center;
        width: 100%;
        margin: 3rem 0 2rem 0;
        @extend %reset;
        @extend %col;

        &Support {
            max-width: 100%;
            margin-bottom: 1rem;
            color: $colorDark;
            font-weight: 500;
            font-size: 2.5rem;
            line-height: 1.1;
            text-align: center;
            @extend %reset;
        }

        &Input {
            width: 100%;
            height: 8rem;
            color: $colorDark;
            font-weight: 500;
            font-size: 2.3rem;
            text-align: center;
            background: #f3f3f3;
            border: 0.4rem solid $colorDark;
            border-radius: 1.5rem;
            @extend %reset;
        }

        &Success {
            align-items: center;
            width: 100%;
            margin: 3rem 0 3rem 0;
            padding: 2.5rem;
            background: #93d500;
            border: 0.4rem solid $colorDark;
            border-radius: 3rem;
            @extend %reset;
            @extend %col;

            &Text {
                max-width: 100%;
                color: $colorDark;
                font-weight: 500;
                font-size: 2.3rem;
                line-height: 1.3;
                text-align: center;
                @extend %reset;

                & + & {
                    margin-top: 1rem;
                }

                & a {
                    display: inline-block;
                    color: #de261f;
                    font-weight: 500;
                    line-height: 0.9;
                    border-bottom: 1px solid #de261f;
                    @extend %reset;
                    @extend %click;
                }
            }
        }
    }

    &__game {
        position: relative;
        z-index: 1;
        width: 50rem;
        height: 50rem;
        margin-bottom: 3rem;
        overflow: hidden;
        border: 0.6rem solid $colorDark;
        border-radius: 3rem;
        @extend %reset;

        @include media(0, $mediaM) {
            width: 100%;
            height: 45rem;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -2;
            width: 100%;
            height: 100%;
            // @include bg('../media/quick-prize-back.png', cover, center);
            transition: $trans;
            content: "";
            will-change: transform;
        }

        &._start::before {
            transform: scale(1.05);
        }

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: rgba(#000, 0.3);
            transition: $trans;
            content: "";
        }

        &:not(._complete)::after {
            opacity: 0;
        }

        &Loader {
            @extend %reset;
            @extend %loader;

            &Item {
                @extend %reset;
                @extend %loaderItem;
                @include loaderColor(#fff);
            }
        }

        &:not(._ready) &ButtonWrapper {
            opacity: 0;
            pointer-events: none;
        }

        &Button {
            width: 100%;
            padding: 2rem 1rem;
            color: #de261f;
            font-weight: 600;
            font-size: 2.4rem;
            line-height: 1.3;
            text-align: center;
            text-transform: uppercase;
            background: #fcfcfc;
            border-radius: 36rem;
            transition: $trans;
            @extend %reset;
            @extend %click;
            @include button(0.97);

            @include media(0, $mediaM) {
                padding: 1.5rem 1rem;
                font-size: 2.2rem;
                line-height: 1.2;
            }

            &Wrapper {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
                width: calc(100% - 6rem);
                transform: translate(-50%, -50%);
                transition: $trans;
                will-change: transform;
                @extend %reset;

                @include media(0, $mediaM) {
                    width: calc(100% - 4rem);
                }
            }
        }

        &._start &ButtonWrapper {
            transform: translate(-50%, -50%) scale(0.8);
            opacity: 0;
        }

        &:not(._complete) &PrizeSupport,
        &:not(._complete) &PrizeName {
            opacity: 0;
        }

        &:not(._complete) &PrizeSupport {
            margin-top: -1rem;
        }

        &:not(._complete) &PrizeName {
            transform: translate(0, 1rem);
        }

        &._complete &PrizeDecor {
            opacity: 0;
        }

        &Prize {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 3;
            width: 29rem;
            height: 29rem;
            background: #fff;
            border: 0.4rem solid $colorDark;
            border-radius: 3.5rem;
            transform: translate(-50%, -50%);
            transition: $trans;
            will-change: transform;
            @extend %reset;
            @extend %col;

            &Support {
                position: absolute;
                top: 0;
                left: 50%;
                z-index: 3;
                padding: 1.3rem 2rem;
                color: #fff;
                font-weight: 500;
                font-size: 2rem;
                white-space: nowrap;
                background: #10cfc9;
                border: 0.4rem solid $colorDark;
                border-radius: 36rem;
                transform: translate(-50%, -50%);
                transition: $trans;
                @extend %reset;

                &:not(._show) {
                    opacity: 0;
                }
            }

            &Name {
                max-width: 90%;
                margin: auto auto 1.5rem auto;
                padding: 0 2rem;
                color: $colorDark;
                font-weight: 500;
                font-size: 2.2rem;
                line-height: 1.3;
                text-align: center;
                transition: $trans;
                @extend %reset;
            }

            &Item {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 20rem;
                height: 20rem;
                object-fit: contain;
                transform: translate(-50%, calc(-50% - 2rem));
                transition: $trans;
                @extend %reset;

                &:not(._current) {
                    opacity: 0;

                    &._1 {
                        margin: -0.5rem 0 0 -0.5rem;
                    }

                    &._2 {
                        margin: 0.5rem 0 0 0.5rem;
                    }

                    &._3 {
                        margin: -0.5rem 0 0 0.5rem;
                    }

                    &._4 {
                        margin: 0.5rem 0 0 -0.5rem;
                    }
                }
            }

            &Decor {
                position: absolute;
                object-fit: contain;
                transition: $trans;
                @extend %reset;

                &._1 {
                    top: -3.5rem;
                    left: 5rem;
                    width: 8.5rem;
                    height: 8.5rem;
                }

                &._2 {
                    top: 5rem;
                    left: 1.5rem;
                    width: 4rem;
                    height: 4rem;
                }

                &._3 {
                    right: 6.25rem;
                    bottom: 1.7rem;
                    width: 3.5rem;
                    height: 3.5rem;
                }

                &._4 {
                    right: 4rem;
                    bottom: 3.5rem;
                    width: 2.7rem;
                    height: 2.7rem;
                }

                &._5 {
                    right: 1.5rem;
                    bottom: 5.5rem;
                    width: 3rem;
                    height: 3rem;
                }
            }
        }

        &:not(._start) &Prize {
            transform: translate(-50%, -50%) scale(1.1);
            opacity: 0;
            pointer-events: none;
        }

        &._start &PrizeDecor {
            &._1 {
                animation: 0.7s gameDecor1 infinite ease-in-out;
                @keyframes gameDecor1 {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(0, -1.2rem);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }
            }

            &._2 {
                animation: 0.7s gameDecor2 infinite ease-in-out;
                animation-delay: 0.2s;
                @keyframes gameDecor2 {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(-1.5rem, 0);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }
            }

            &._3 {
                animation: 0.7s gameDecor3 infinite ease-in-out;
                @keyframes gameDecor3 {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(0.2rem, 1rem);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }
                // animation-delay: .2s;
            }

            &._4 {
                animation: 0.7s gameDecor4 infinite ease-in-out;
                @keyframes gameDecor4 {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(1rem, 1rem);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }
            }

            &._5 {
                animation: 0.7s gameDecor5 infinite ease-in-out;
                animation-delay: 0.1s;
                @keyframes gameDecor5 {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(1rem, 0.5rem);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }
            }
        }
    }

    &__prizeInfo {
        max-width: 100%;
        padding: 0 3rem;
        color: $colorDark;
        font-size: 2rem;
        line-height: 1.3;
        text-align: center;
        @extend %reset;
    }

    &__table {
        position: relative;
        width: 100%;
        padding: 1.5rem 1.5rem 0 1.5rem;
        background: #fff;
        border-radius: 3rem;
        @extend %reset;

        &Row {
            align-items: stretch;
            width: 100%;
            padding: 0 2rem;
            border: 0.4rem solid transparent;
            @extend %reset;
            @extend %row;

            @include media(0, $mediaM) {
                padding: 0 1rem;
            }

            &._head {
                margin-bottom: 0.5rem;
                background: #5c0f8b;
                border-radius: 2rem;
            }

            &:not(._head):not(:last-child) {
                border-bottom: 2px solid rgba(#f7a600, 0.4);
            }
        }

        &Row._head &Col {
            color: #fff;
            font-family: "Meloriac";
            text-transform: uppercase;
        }

        &Col {
            align-items: center;
            justify-content: center;
            padding: 1.3rem 1rem;
            color: $colorDark;
            font-weight: 500;
            font-size: 2.3rem;
            line-height: 1.3;
            text-align: center;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                font-size: 2.2rem;
            }

            &._negative {
            }

            &._date {
                width: 27%;

                @include media(0, $mediaM) {
                    width: 30%;
                }
            }

            &._counter {
                width: 25%;
            }

            &._comment {
                width: 48%;

                @include media(0, $mediaM) {
                    width: 45%;
                }
            }
        }

        &Content {
            width: 100%;
            max-height: 42rem;
            overflow-y: auto;
            @extend %reset;
        }

        &Content &Row {
            padding: 0;
        }
    }

    &__regInfo {
        padding: 0.8rem 1.5rem 0.7rem 1.5rem;
        color: #fff;
        font-weight: 600;
        font-size: 1.9rem;
        line-height: 1.3;
        text-align: center;
        background: #ff59cf;
        border-radius: 36rem;
        @extend %reset;
    }

    &__foot._afterLogin &__error:not(._empty) {
        margin-top: 2rem;
    }

    &__confirm {
        position: relative;
        width: 100%;
        transition: $trans;
        @extend %reset;

        &Block {
            position: absolute;
            top: 0;
            left: 0;
            align-items: center;
            width: 100%;
            padding-top: 3rem;
            transition: $trans;
            @extend %reset;
            @extend %col;

            &:not(._show) {
                opacity: 0;

                &._prev {
                    transform: translate(-5rem, 0);
                }

                &._next {
                    transform: translate(5rem, 0);
                }
            }
        }

        &._static &Block {
            position: relative;

            &:not(._show) {
                display: none;
            }
        }

        &Password {
            align-items: center;
            width: 100%;
            margin-bottom: 3rem;
            @extend %reset;
            @extend %col;

            &Title {
                max-width: 100%;
                margin-bottom: 1.5rem;
                color: #a8278e;
                font-weight: 600;
                font-size: 2.2rem;
                text-align: center;
                @extend %reset;
            }

            &Box {
                align-items: center;
                justify-content: center;
                width: 33rem;
                height: 7rem;
                margin-bottom: 1.3rem;
                border: 0.4rem solid #f3f3f3;
                border-radius: 1.5rem;
                transition: $trans;
                @extend %reset;
                @extend %row;

                &Copy {
                    display: inline-block;
                    width: 2.5rem;
                    height: 2.5rem;
                    margin-left: 0.7rem;
                    transform: translate(0, 0.4rem);
                    @extend %reset;
                    @extend %click;
                }
            }

            &Inner {
                justify-content: center;
                width: 100%;
                color: $colorDark;
                font-weight: 600;
                font-size: 2.8rem;
                transition: $trans;
                @extend %row;
            }

            &._copySuccess &Inner {
                color: #ff59cf;
            }

            &Support {
                max-width: 100%;
                color: $colorDark;
                font-size: 2rem;
                line-height: 1.3;
                text-align: center;
                @extend %reset;

                @include media(0, $mediaM) {
                    max-width: 90%;
                }
            }
        }

        &Button {
            @extend %reset;
        }
    }

    &__cupon {
        align-items: center;
        width: 100%;
        padding-bottom: 3.5rem;
        @extend %reset;
        @extend %col;

        &Code {
            width: 100%;
            height: 15rem;
            margin-bottom: 3rem;
            object-fit: contain;
            @extend %reset;
        }

        &Description {
            max-width: 100%;
            margin-bottom: 2rem;
            color: $colorDark;
            font-size: 1.9rem;
            line-height: 1.3;
            text-align: center;
            @extend %reset;

            @include media(0, $mediaM) {
                max-width: 90%;
                font-size: 2.1rem;
            }
        }

        &Info {
            width: 100%;
            padding: 2.5rem;
            color: $colorDark;
            font-size: 1.45rem;
            line-height: 1.35;
            background: #f4f4f4;
            border-radius: 1.5rem;
            @extend %reset;

            @include media(0, $mediaM) {
                font-size: 1.5rem;
                border-radius: 1.8rem;
            }
        }
    }

    &__referal {
        align-items: center;
        padding: 3rem 4rem;
        background: #fff;
        border-radius: 3rem;
        @extend %reset;
        @extend %col;

        &Text {
            color: $colorDark;
            font-size: 2rem;
            line-height: 1.35;
            text-align: center;
            @extend %reset;

            & b {
                display: block;
                margin-bottom: 1rem;
                color: #5c0f8b;
                text-transform: uppercase;
                @extend %reset;
            }

            & + & {
                margin-top: 2rem;
            }

            & span {
                color: #5c0f8b;
                font-weight: 600;
            }
        }

        &Link {
            display: block;
            margin: 1rem 0 0 0;
            padding: 1.3rem 1.6rem;
            color: $colorDark;
            font-weight: 500;
            font-size: 2.1rem;
            background: #ffd600;
            border-radius: 1rem;
            @extend %reset;
            @extend %click;
        }

        &Copy {
            @extend %reset;

            &._parent:not(._empty) {
                margin: 2.5rem 0 0 0;
            }

            &Inner {
                padding: 1.1rem 2rem 1.2rem 2rem;
                color: #fff;
                font-weight: 500;
                font-size: 1.9rem;
                white-space: nowrap;
                background: #5c0f8b;
                border-radius: 36rem;
                @extend %reset;
            }
        }
    }

    &__merch {
        align-items: center;
        width: 40rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            width: 100%;
        }

        &Head {
            position: relative;
            width: 100%;
            height: 30rem;
            margin-bottom: 1.5rem;
            padding: 3rem;
            background: #fff;
            border-radius: 4rem;
            @extend %reset;
        }

        &Image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            @extend %reset;
        }

        &Success {
            margin-top: 2rem;
            padding: 0.6rem 1.2rem 0.5rem 1.2rem;
            color: #fff;
            font-weight: 400;
            font-size: 1.7rem;
            line-height: 1.3;
            text-align: center;
            text-transform: uppercase;
            background: #50ae30;
            border-radius: 1rem;
            @extend %reset;

            @include media(0, $mediaM) {
                padding: 0.7rem 1.5rem 0.7rem 1.5rem;
                font-weight: 600;
                font-size: 2.1rem;
            }
        }

        &Count {
            position: absolute;
            right: 3rem;
            bottom: 3rem;
            align-items: center;
            color: #ff6b00;
            font-size: 1.3rem;
            font-family: "Meloriac";
            @extend %reset;
            @extend %col;

            &Title {
                align-items: center;
                justify-content: center;
                width: 6rem;
                height: 6rem;
                margin-bottom: 0.5rem;
                color: #fff;
                font-size: 3rem;
                font-family: "Meloriac";
                text-align: center;
                background: #ff6b00;
                border-radius: 50%;
                @extend %reset;
                @extend %col;
            }
        }

        &Tips {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;
            @extend %reset;

            &Content {
                display: flex;
                align-items: center;
                height: 2.5rem;
                padding: 0.4rem 1rem;
                color: #fff;
                font-weight: 600;
                font-size: 1.6rem;
                white-space: nowrap;
                text-transform: uppercase;
                background: #6bc4e9;
                border-radius: 1rem;
                @extend %reset;
                @include media(0, $mediaM) {
                    height: 3rem;
                    font-size: 1.8rem;
                }
            }

            &Info {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2.5rem;
                height: 2.5rem;
                margin-left: 0.5rem;
                color: #fff;
                font-weight: 600;
                font-size: 1.7rem;
                border: 2px solid #fff;
                border-radius: 50%;
                @extend %reset;
                @extend %click;
                @include media(0, $mediaM) {
                    width: 3rem;
                    height: 3rem;
                }

                &:hover &Alert {
                    opacity: 1;
                }

                &Alert {
                    position: absolute;
                    bottom: 0.5rem;
                    left: 100%;
                    z-index: 2;
                    width: 22rem;
                    margin-left: 1rem;
                    padding: 2.5rem;
                    color: #fff;
                    font-size: 1.8rem;
                    line-height: 1.35;
                    background: #ff6b00;
                    border-radius: 2rem;
                    box-shadow: 0.8rem 0.8rem 0.8rem rgba(#610000, 0.1);
                    cursor: default;
                    opacity: 0;
                    transition: $trans;
                    pointer-events: none;
                    @extend %reset;

                    @include media(0, $mediaM) {
                        bottom: 100%;
                        left: 50%;
                        margin-bottom: 1rem;
                        margin-left: 0;
                        padding: 2rem;
                        font-size: 1.9rem;
                        transform: translate(-50%, 0);
                    }
                }
            }
        }

        &Size {
            align-items: center;
            width: 100%;
            height: 7rem;
            padding: 0 0 0 2.5rem;
            background: #ffd600;
            border-radius: 2rem;
            @extend %reset;
            @extend %row;

            @include media(0, $mediaM) {
                height: 8rem;
            }

            &Support {
                color: #5c0f8b;
                font-size: 2.3rem;
                @extend %reset;
            }

            &Select {
                width: calc(100% - 12rem);
                height: 100%;
                margin-left: auto;
                @extend %reset;

                & .select__view {
                    border: 2px solid rgba(#ffd600, 0.5);
                }
            }
        }

        &Confirm {
            margin-top: 3.5rem;
            @extend %reset;
        }
    }

    &__ruffle {
        align-items: center;
        width: 100%;
        padding: 3rem;
        background: #fff;
        border-radius: 3rem;
        @extend %reset;
        @extend %col;

        &Text {
            max-width: 85%;
            margin-bottom: 1.5rem;
            font-size: 2.1rem;
            line-height: 1.3;
            text-align: center;
            @extend %reset;
        }

        &Result {
            color: #5c0f8b;
            font-weight: 500;
            font-size: 2.2rem;
            text-transform: uppercase;
            @extend %reset;

            & span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 4.5rem;
                height: 4.5rem;
                margin-left: 0.5rem;
                color: #fff;
                font-weight: 500;
                font-size: 2.5rem;
                background: #ff6b00;
                border-radius: 50%;
            }
        }
    }
}
