.cookies {
    align-items: center;
    width: 100%;
    padding: 3rem 3rem 2.5rem 3rem;
    background: #fff;
    border-radius: 3rem;
    @extend %reset;
    @extend %col;

    &__content {
        max-width: 89%;
        margin-bottom: 1.5rem;
        color: $colorDark;
        font-size: 1.9rem;
        line-height: 1.3;
        text-align: center;
        @extend %reset;

        @include media(0, $mediaM) {
            max-width: 100%;
            font-size: 1.95rem;
        }
    }

    &__buttons {
        align-items: center;
        margin-bottom: 2.5rem;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            flex-direction: column;
            width: 100%;
        }
    }

    &__button {
        width: 24rem;
        @extend %reset;

        @include media(0, $mediaM) {
            width: 100%;
        }

        & .button__content {
            font-size: 1.8rem !important;

            @include media(0, $mediaM) {
                font-size: 2.1rem !important;
            }
        }

        &+& {
            margin-left: 1rem;

            @include media(0, $mediaM) {
                margin-top: 1rem;
                margin-left: 0;
            }
        }
    }

    &__link {
        color: $colorMain;
        font-size: 1.9rem;
        border-bottom: 1px solid rgba($colorMain, .6);
        @extend %reset;
        @extend %click;

        @include media(0, $mediaM) {
            font-size: 2.2rem;
        }
    }
}