.faqForm {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 5rem 0 20rem 0;
    overflow: hidden;
    @extend %reset;
    @extend %section;

    @include media(0, $mediaM) {
        padding: 2rem 0 14rem 0;
    }

    &__inner {
        align-items: center;
        padding: 9rem 5rem;
        background: #f7a600;
        border-radius: 4.5rem;
        @extend %reset;
        @extend %inner;

        @include media(0, $mediaM) {
            padding: 3rem 2rem 5rem 2rem;
            border-radius: 3.5rem;
        }
    }

    &__head {
        width: 100%;
        margin-bottom: 5.5rem;
        @extend %reset;

        @include media(0, $mediaM) {
            max-width: 90%;
            margin-bottom: 4.5rem;
        }
    }

    &__content {
        align-items: center;
        width: 100%;
        @extend %reset;
        @extend %col;

        &Inner {
            width: 89rem;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }
    }

    &._anket &__content {
        max-width: 88rem;
    }
}
