.indexWeeksPrizes {
    width: 100%;
    padding: 9rem 0 22rem 0;
    @extend %reset;
    @extend %section;
    @include bg('../media/back-main-3.jpg', 100% auto, center -12rem);

    @include media(0, $mediaM) {
        padding: 8rem 0 14rem 0;
        @include bg('../media/back-main-3-mob.jpg', 100% auto, center -10rem);
    }

    &__inner {
        align-items: center;
        transition: $trans;
        @extend %reset;
        @extend %inner;
    }

    &:not(._ready) &__inner {
        opacity: 0;
    }

    &__head {
        align-items: center;
        width: 100%;
        margin-bottom: 5rem;
        @extend %reset;
        @extend %col;

        &Title {
            max-width: 100%;
            margin-bottom: 3rem;
            text-align: center;
            @extend %reset;

            @include media(0, $mediaM) {
                margin-bottom: 2rem;
            }
        }

        &Description {
            width: 100%;
            margin-bottom: 1.8rem;
            color: #fff;
            font-weight: 600;
            font-size: 2.4rem;
            text-align: center;
            text-transform: uppercase;
            @extend %reset;

            @include media(0, $mediaM) {
                font-weight: 500;
                font-size: 2.3rem;
                line-height: 1.4;
            }
        }

        &Info {
            @extend %reset;

            @include media(0, $mediaM) {
                text-align: center;
                // width: calc(100% + 1rem);
            }
        }
    }

    &__content {
        position: relative;
        width: 100%;
        @extend %reset;

        &._top {
            margin-bottom: 9.5rem;
            padding: 0 13rem;

            @include media(0, $mediaM) {
                margin-bottom: 9.5rem;
                padding: 0;
            }
        }
    }

    &__btn {

        display: none;
        margin-top: -3rem;
        @extend %arrow;

        @include media(0, $mediaM) {
            display: block;
        }

        &._prev {
            left: 0;
        }

        &._next {
            right: 0;
        }
    }

    &__content._top &__btn {
        display: block;
    }

    &__items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @extend %reset;

        @include media(0, $mediaM) {
            display: inline-flex;
            justify-content: flex-start;
            width: auto;
        }

        &._top {
            display: inline-flex;
            justify-content: flex-start;
            width: auto;
        }
    }

    &__item {
        align-items: center;
        transition: $trans;
        will-change: transform;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            width: calc(100vw - 4rem);
        }

        &Head {
            width: 100%;
            margin-bottom: 2rem;
            @extend %reset;
        }

        &Image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            @extend %reset;
        }

        &Description {
            max-width: 100%;
            color: #fff;
            font-size: 2.5rem;
            font-family: 'Meloriac';
            line-height: 1.3;
            letter-spacing: .1rem;
            text-align: center;
            text-transform: uppercase;
            @extend %reset;

            @include media(0, $mediaM) {
                font-size: 2.7rem;
            }
        }
    }

    &__items._top &__item {
        width: 37rem;

        @include media(0, $mediaM) {
            width: calc(100vw - 4rem);
        }

        &Head {
            position: relative;
            z-index: 1;
            height: 30rem;
            margin-bottom: -1.5rem;

            @include media(0, $mediaM) {
                height: 32rem;
                margin-bottom: -2.5rem;
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                width: 23rem;
                height: 23rem;
                margin-top: -3rem;
                transform: translate(-50%, -50%);
                content: "";

                @include media(0, $mediaM) {
                    width: 24rem;
                    height: 24rem;
                    margin-top: -4rem;
                }
            }
        }
    }

    &__items._top &__item {
        transform: scale(.8);
        opacity: 0;

        @include media(0, $mediaM) {
            transform: scale(1);
            opacity: 1;
        }
    }

    &__items._top &__item[data-current],
    &__items._top &__item[data-current]+&__item,
    &__items._top &__item[data-current]+&__item+&__item {
        transform: scale(1);
        opacity: 1;
    }

    &__items._top &__item._1 &__itemHead::before {
        @include bg('../media/prize-form-1-1.svg', contain, center);
    }

    &__items._top &__item._2 &__itemHead::before {
        @include bg('../media/prize-form-1-2.svg', contain, center);
    }

    &__items._top &__item._3 &__itemHead::before {
        @include bg('../media/prize-form-1-3.svg', contain, center);
    }

    &__items._top &__item._4 &__itemHead::before {
        @include bg('../media/prize-form-1-4.svg', contain, center);
    }

    &__items._bottom {
        justify-content: center;
        margin-top: 2rem;

        @include media(0, $mediaM) {
            margin-left: 3.5rem;
        }
    }

    &__items._bottom &__item {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 29%;
        margin: 0 3.5rem;
        padding: 5rem 2rem 2.5rem 2rem;
        background: #5C0F8B;
        border-radius: 5.5rem;

        @include media(0, $mediaM) {
            width: calc(100vw - 11rem);
            margin: 0;
            margin-right: 11rem;
        }

        &::after {
            position: absolute;
            top: 100%;
            left: 2rem;
            z-index: 2;
            width: calc(100% - 4rem);
            height: 1rem;
            margin-top: 4.5rem;
            background: rgba(#250003, .25);
            filter: blur(1rem);
            content: "";
        }

        &Head {
            position: relative;
            z-index: 1;
            height: 21rem;
            margin-top: -11rem;
            margin-bottom: -.5rem;

            @include media(0, $mediaM) {
                height: 23.5rem;
            }
        }
    }

    &__content._bottom &__pagination {
        @include media(0, $mediaM) {
            margin-top: 7rem;
        }
    }

    &__pagination {

        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 2rem;

        @include media(0, $mediaM) {
            display: flex;
        }

        &Item {
            width: 1rem;
            height: 1rem;
            margin: 0 1rem;
            background: #fff;
            border-radius: 50%;
            transition: $trans;

            &:not(._current) {
                opacity: 0.3;
            }
        }
    }
}