.page {
    width: 100%;
    min-height: 100%;
    // transform: translate(0, -500px);
    background: #ed6b04;
    @extend %reset;
    @extend %col;

    &._inner {
        @extend %innerPage;
    }

    &__section {
        position: relative;
        width: 100%;
        @extend %reset;
        // z-index: 1;

        &._indexPrizes {
            z-index: 2;
            margin-top: -6.5rem;
        }

        &._indexCity {
            z-index: 3;
            margin-top: -9rem;
        }

        &._indexChildPrizes {
            z-index: 4;
            margin-top: -7rem;
        }

        &._footer {
            margin-top: auto;
        }

        &Inner {
            align-items: center;
            margin: 0 auto;
            padding: 0 0 10rem 0;
            @extend %inner;
            @extend %col;

            @include media(0, $mediaM) {
                padding-top: 1.5rem;
            }
        }
    }

    &._inner &__section._footer {
        z-index: 3;

        @include media(0, $mediaM) {
            &::before {
                position: absolute;
                top: -7rem;
                left: 0;
                z-index: 1;
                width: 19rem;
                height: 19rem;
                content: "";
                pointer-events: none;
            }
        }
    }
}
