.upload {
    position: relative;
    z-index: 1;
    width: 100%;
    @extend %reset;
    @include button(0.98);

    @keyframes runStroke {
        0% {
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dashoffset: 100%;
        }
    }

    &__input {
        display: none;
    }

    &__back {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        pointer-events: none;
        @extend %reset;

        & .dashedBorder__rect {
            stroke: #5c0f8b;
        }
    }

    &:hover .dashedBorder__rect {
        animation: runStroke 7s infinite linear;
    }

    &__content {
        position: relative;
        z-index: 1;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 15rem;
        padding: 3rem;
        background: #fff;
        border-radius: 2.5rem;
        @extend %reset;
        @extend %click;
        @extend %col;
    }

    &._main &__content {
        height: 13rem;
        background: #f3f3f3;

        @include media(0, $mediaM) {
            height: 15rem;
        }
    }

    &__icon {
        width: 3rem;
        height: 3rem;
        margin-bottom: 0.5rem;
        @extend %reset;
        @include icon(#5C0F8B);

        @include media(0, $mediaM) {
            width: 3.5rem;
            height: 3.5rem;
        }
    }

    &._main &__icon {
        display: none;
    }

    &__description {
        max-width: 100%;
        color: #5C0F8B;
        font-size: 1.9rem;
        line-height: 1.3;
        text-align: center;
        @extend %reset;

        @include media(0, $mediaM) {
            font-size: 2rem;
        }
    }

    &._main &__description {
        color: #5c0f8b;
        font-size: 1.9rem;
    }

    &__over {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: #93d500;
        border-radius: 2.5rem;
        transition: $trans;
        @extend %reset;
        @extend %col;

        &:not(._show) {
            opacity: 0;
        }
    }

    &__over &__description {
        color: $colorDark;
    }

    &__result {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 2.5rem;
        transition: $trans;
        @extend %reset;
        @extend %col;

        &:not(._show) {
            opacity: 0;
        }

        &Files {
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            margin-bottom: 1rem;
            @extend %reset;
            @extend %row;
        }

        &Files &File {
            margin: 0 0.5rem 1rem 0.5rem;
        }

        &File {
            align-items: center;
            margin-bottom: 2rem;
            @extend %reset;
            @extend %row;

            &Name {
                display: block;
                max-width: 16rem;
                padding: 1rem 1.7rem 0.9rem 1.7rem;
                overflow: hidden;
                color: $colorDark;
                font-weight: 500;
                font-size: 1.7rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                background: #93d500;
                border: 0.3rem solid $colorDark;
                border-radius: 36rem;
                @extend %reset;

                @include media(0, $mediaM) {
                    font-size: 2.1rem;
                }
            }

            &Button {
                margin-left: 1.5rem;
                color: $colorDark;
                font-size: 1.9rem;
                border-bottom: 1px dashed $colorDark;
                @extend %reset;
                @extend %click;

                @include media(0, $mediaM) {
                    font-size: 2.1rem;
                }
            }
        }

        &Btn {
            align-items: center;
            color: $colorDark;
            font-size: 1.9rem;
            @extend %reset;
            @extend %click;
            @extend %row;

            @include media(0, $mediaM) {
                font-size: 2.1rem;
            }

            &Icon {
                width: 2rem;
                height: 2rem;
                margin-top: -0.5rem;
                margin-right: 1rem;
                @extend %reset;
                @include icon($colorDark);

                @include media(0, $mediaM) {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
        }
    }
}
