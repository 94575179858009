.waitHeader {
    @extend %reset;
    @extend %section;
    width: 100%;
    @include bg('../media/zagl-back.jpg', cover, center top);

    @include media(0, $mediaM) {
        @include bg('../media/zagl-back-mob.jpg', 100% auto, center top);
    }

    &__inner {
        @extend %reset;
        @extend %col;
        width: 120.5rem;
        min-height: 100vh;
        padding-top: 3rem;

        @include media(0, $mediaM) {
            width: 92%;
            padding-top: 2.5rem;
            align-items: center;
        }
    }

    &__head {
        @extend %row;
        width: 100%;
        justify-content: space-between;

        @include media(0, $mediaM) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
    }

    &__logo {
        @extend %reset;
        width: 16.5rem;
        margin-top: 1.2rem;

        @include media(0, $mediaM) {
            width: 13rem;
            margin-top: 0;
            margin-bottom: 2.5rem;
        }
    }

    &:not(._ready) &__timer {
        opacity: 0;
    }

    &__timer {
        @extend %reset;
        width: 38rem;
        background: #562483;
        border-radius: 4.3rem;
        padding: 3.9rem 3rem 3rem 3rem;
        margin-right: 2rem;
        transition: $trans;

        @include media(0, $mediaM) {
            width: 100%;
            margin-right: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 3.2rem;
            padding: 2.5rem;
        }

        &Head {
            width: 100%;
            margin-bottom: 1.8rem;

            @include media(0, $mediaM) {
                margin-bottom: 0;
            }
        }

        &Support {
            @extend %reset;
            color: #E1C430;
            text-transform: uppercase;
            font-size: 1.9rem;
            font-weight: 600;
            margin-bottom: 1rem;

            @include media(0, $mediaM) {
                margin-bottom: 0.7rem;
            }
        }

        &Counter {
            @extend %reset;
            font-family: 'Meloriac';
            font-size: 3.1rem;
            color: #fff;
            margin-left: 0.9rem;

            @include media(0, $mediaM) {
                margin-left: 0;
            }

            & span {
                font-size: 7.8rem;
                margin-right: 1rem;
            }
        }

        &Date {
            @extend %reset;
            @extend %row;
            width: 100%;
            background: #5AB0D4;
            padding: 1.8rem 1.5rem;
            border-radius: 0 2.5rem 2.5rem 2.5rem;
            color: #fff;
            font-size: 1.7rem;
            text-transform: uppercase;
            font-weight: 600;
            justify-content: center;
            align-items: center;

            & span+span {
                margin-left: .5rem;
            }

            @include media(0, $mediaM) {
                width: 33rem;
                font-size: 1.9rem;
                line-height: 1.2;
                padding: 1.8rem 2.2rem;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                border-radius: 0 2rem 2rem 2rem;

                & span+span {
                    margin-top: 1.3rem;
                    margin-left: 0;
                }
            }
        }
    }

    &__button {
        @extend %reset;
        @extend %click;
        font-weight: 600;
        color: #fff;
        border-radius: 36rem;
        border: 2px solid #fff;
        padding: 1.4rem 2rem 1.3rem 2rem;
        text-transform: uppercase;
        font-size: 1.7rem;
        transition: $trans;

        @include media(0, $mediaM) {
            margin-top: 72rem;
            font-size: 2.1rem;
            padding: 1.9rem 3rem 1.8rem 3rem;
        }

        &:hover {
            background: #fff;
            color: #F08500;
        }
    }

    &:not(._ready) &__footer {
        opacity: 0;
    }

    &__footer {
        @extend %reset;
        width: calc(100% - 3.5rem);
        margin-top: 85rem;

        @include media(0, $mediaM) {
            width: 100vw;
            margin-top: 72rem;
        }

        &Inner {
            @extend %reset;
            width: 100%;
            border-radius: 4rem 4rem 0 0;
            background: #145A28;
            color: #E1C430;
            font-size: 1.4rem;
            line-height: 1.6;
            text-align: center;
            padding: 5.5rem 9rem;
            transition: $trans;

            @include media(0, $mediaM) {
                font-size: 1.75rem;
                padding: 5rem 5rem;
            }
        }
    }
}