.error {
    @extend %reset;
    width: 100%;
    height: 100%;
    background: #E5007D;
    color: #fff;
    border-radius: 1rem;
    padding: .6rem 1.2rem .5rem 1.2rem;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
    text-transform: uppercase;
    // white-space: nowrap;

    @include media(0, $mediaM) {
        padding: .7rem 1.5rem .7rem 1.5rem;
        font-size: 2.1rem;
        font-weight: 600;
    }
}