.topBar {
    width: 100%;
    height: 100%;
    transition: $trans;
    @extend %reset;
    @extend %section;

    &__inner {
        position: relative;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 0 2.2rem 0 2.2rem;
        background: #1a5632;
        border-radius: 0 0 2.5rem 2.5rem;
        @extend %reset;
        @extend %inner;

        @include media(0, $mediaM) {
            width: 100%;
            padding: 0 2rem;
        }
    }

    &__block {
        @extend %reset;

        &._logo {
            justify-content: center;
            height: 10rem;
            padding-left: 1rem;
            @extend %col;

            @include media(0, $mediaM) {
                position: absolute;
                top: 50%;
                left: 50%;
                height: 7rem;
                padding-left: 0;
                transform: translate(-50%, -50%);
            }
        }

        &._nav {
            @include media(0, $mediaM) {
                display: none;
            }
        }

        &._buttons {
            @include media(0, $mediaM) {
                display: none;
            }
        }
    }

    &__logo {
        width: 15rem;
        transition: $trans;
        pointer-events: visible;
        @extend %reset;
        @extend %click;

        @include media(0, $mediaM) {
            width: 13rem;
        }

        &Icon {
            display: block;
            width: 100%;
            @extend %reset;
        }
    }

    &__nav {
        align-items: center;
        @extend %reset;
        @extend %row;

        &Item {
            margin: 0 1.3rem;
            transition: $trans;
            @extend %reset;

            &:not(._ready) {
                opacity: 0;
                pointer-events: none;
            }
        }

        &Link {
            color: #fff;
            font-weight: 600;
            font-size: 1.9rem;
            text-transform: uppercase;
            pointer-events: visible;
            @extend %reset;
            @extend %click;
            @include button(0.97);

            &:hover {
                color: #ffd600;
            }
        }
    }

    &__buttons {
        position: relative;
        align-items: center;
        height: 4.5rem;
        transition: $trans;
        @extend %reset;
        @extend %row;
    }

    &__button {
        @extend %reset;

        & + & {
            margin-left: 2rem;
        }
    }

    &__menu {
        position: relative;
        display: none;
        width: 4rem;
        height: 2rem;
        pointer-events: visible;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            display: flex;
        }

        &Item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform-origin: center;
            transition: $trans;
            will-change: transform;
            @extend %reset;
            @extend %col;

            &::before {
                width: 100%;
                height: 0.4rem;
                margin: auto;
                background: #f7a600;
                content: "";
            }

            &:nth-child(1) {
                transform: translate(0, -0.7rem);
            }

            &:nth-child(2) {
                transform: translate(0, 0.7rem);
            }
        }

        &._active &Item {
            &:nth-child(1) {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                transform: rotate(-45deg);
            }
        }
    }

    &__icon {
        display: none;
        width: 4rem;
        height: 4rem;
        object-fit: contain;
        pointer-events: visible;
        @extend %reset;

        @include media(0, $mediaM) {
            display: block;
        }
    }
}
