.indexCity {
    position: relative;
    width: 100%;
    padding: 8.5rem 0 14rem 0;
    background-color: #1a5632;
    border-radius: 8rem;
    @extend %reset;
    @extend %section;
    @include bg("../media/back-main-2.svg", cover, center);

    @include media(0, $mediaM) {
        padding: 6rem 0 26rem 0;
        background-color: #1a5632;
        border-radius: 6rem;
        @include bg("../media/back-main-2-mob.svg", cover, center top);
    }

    &::before {
        position: absolute;
        top: -2.2rem;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 20rem;
        background: #510a7c;
        border-radius: 8rem 8rem 0 0;
        content: "";

        @include media(0, $mediaM) {
            top: -2.5rem;
            border-radius: 6rem 6rem 0 0;
        }
    }

    &__inner {
        align-items: center;
        transition: $trans;
        @extend %reset;
        @extend %inner;

        @include media(0, $mediaM) {
            width: 100%;
        }
    }

    &:not(._ready) &__inner {
        opacity: 0;
    }

    &__title {
        max-width: 80%;
        margin-bottom: 6.5rem;
        text-align: center;
        @extend %reset;

        @include media(0, $mediaM) {
            max-width: 100%;
            margin-bottom: 8rem;
            padding: 0 3rem;
        }

        &Info {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            margin-right: -2rem;
            margin-left: 1.5rem;
            padding-left: 0.2rem;
            color: #fff;
            font-weight: 600;
            font-size: 1.8rem;
            font-family: "Geologica";
            text-align: center;
            background: #ff6b00;
            border-radius: 50%;
            transform: translate(0, -1.8rem);
            cursor: pointer;
            @extend %reset;

            @include media(0, $mediaM) {
                margin-right: -4rem;
                margin-left: 1rem;
                transform: translate(0, -0.7rem);
            }

            &:not(:hover) &AlertWrapper {
                opacity: 0;
                pointer-events: none;
            }

            &AlertWrapper {
                position: absolute;
                top: 50%;
                left: 100%;
                z-index: 2;
                transform: translate(0, -50%);
                cursor: default;
                transition: $trans;

                @include media(0, $mediaM) {
                    top: auto;
                    right: -10rem;
                    bottom: 100%;
                    left: auto;
                    margin-bottom: 1rem;
                    transform: translate(0, 0);
                }
            }

            &Alert {
                width: 31rem;
                margin-left: 4rem;
                padding: 2rem 2.5rem;
                color: #fff;
                font-size: 1.7rem;
                font-family: "Geologica";
                line-height: 1.4;
                letter-spacing: 0;
                text-align: left;
                background: #ff6b00;
                border-radius: 2rem;
                box-shadow: 0.8rem 0.8rem 0.8rem rgba(#000, 0.15);
                @extend %reset;

                @include media(0, $mediaM) {
                    width: 32rem;
                    padding: 1.5rem 1.7rem;
                }

                & a {
                    display: inline-block;
                    color: #ffd600;
                    text-decoration: underline;
                    cursor: pointer;
                    @extend %reset;
                }
            }
        }
    }

    &__content {
        position: relative;
        align-items: center;
        width: 87%;
        height: 66rem;
        padding: 21rem 6rem 6rem 6rem;
        border-radius: 8rem;
        @extend %reset;
        @extend %col;
        @include bg("../media/back-game-block.jpg", cover, center);

        @include media(0, $mediaM) {
            width: 100%;
            height: 95rem;
            padding: 21rem 3rem 6rem 3rem;
            border-radius: 5rem;
        }

        &::before {
            position: absolute;
            top: -5rem;
            left: 50%;
            z-index: 2;
            width: 46rem;
            height: 30rem;
            transform: translate(-50%, 0);
            content: "";
            @include bg("../media/game-logo.png", contain, center top);

            @include media(0, $mediaM) {
                top: -6rem;
                width: 44rem;
            }
        }
    }

    &__items {
        align-items: center;
        justify-content: center;
        margin-bottom: 6rem;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 4.5rem;
        }
    }

    &__item {
        align-items: center;
        width: 19.5rem;
        margin: 0 2.5rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            margin: 0 1rem;
            margin-bottom: 3rem;
        }

        &Head {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 19.5rem;
            margin-bottom: 3rem;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                margin-bottom: 2rem;
            }

            &::after {
                position: absolute;
                top: 100%;
                left: 3rem;
                z-index: 1;
                width: calc(100% - 6rem);
                height: 0.5rem;
                margin-top: 0.8rem;
                background: rgba(#250003, 0.3);
                filter: blur(0.7rem);
                content: "";
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%);
                content: "";
                @include bg("../media/game-artefact-form.svg", contain, center);
            }
        }

        &Image {
            position: relative;
            z-index: 3;
            width: 15rem;
            height: 15rem;
            margin: auto;
            object-fit: contain;
            @extend %reset;
        }

        &Title {
            max-width: 100%;
            color: #5c0f8b;
            font-size: 2.5rem;
            font-family: "Meloriac";
            letter-spacing: 0.1rem;
            text-align: center;
            @extend %reset;
        }
    }

    &__button {
        position: relative;
        @extend %reset;

        @include media(0, $mediaM) {
            width: 90%;
        }

        &::after {
            position: absolute;
            top: -2.5rem;
            right: 1rem;
            z-index: 2;
            width: 6rem;
            height: 6rem;
            content: "";
            @include bg("../media/stars-btn-2.svg", contain, left top);
        }
    }
}
