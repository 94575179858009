.indexChildPrizes {
    width: 100%;
    padding: 8.5rem 0 21rem 0;
    border-radius: 8rem;
    @extend %reset;
    @extend %section;
    @include bg("../media/back-main-4.jpg", cover, center);

    @include media(0, $mediaM) {
        padding: 6rem 0 16rem 0;
        border-radius: 5rem;
        @include bg("../media/back-main-4-mob.jpg", cover, center top);
    }

    &::before {
        position: absolute;
        top: 9rem;
        right: -1rem;
        z-index: 2;
        width: 31.5rem;
        height: 31.5rem;
        content: "";
        @include bg("/media/mascot-blue.svg", contain, right top);

        @include media(0, $mediaM) {
            top: 26rem;
            right: -0.5rem;
            width: 15.5rem;
            height: 15.5rem;
        }
    }

    &::after {
        position: absolute;
        bottom: 14rem;
        left: -1rem;
        z-index: 2;
        width: 25rem;
        height: 25rem;
        content: "";
        @include bg("../media/mascot-orange.svg", contain, left bottom);

        @include media(0, $mediaM) {
            bottom: 7rem;
            left: 0.5rem;
            width: 13rem;
            height: 13rem;
        }
    }

    &__inner {
        align-items: center;
        transition: $trans;
        @extend %reset;
        @extend %inner;
    }

    &:not(._ready) &__inner {
        opacity: 0;
    }

    &__head {
        align-items: center;
        margin-bottom: 19rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            margin-bottom: 23.5rem;
        }

        &Title {
            max-width: 100%;
            margin-bottom: 1.5rem;
            text-align: center;
            @extend %reset;
        }

        &Description {
            @extend %reset;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        padding: 0 4rem;
        @extend %reset;

        @include media(0, $mediaM) {
            z-index: 3;
            padding: 0;
        }
    }

    &__items {
        justify-content: space-between;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            display: inline-flex;
            justify-content: flex-start;
        }
    }

    &__item {
        align-items: center;
        width: 18.3%;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            width: calc(100vw - 3.5rem);
            margin-right: 3.5rem;
            padding: 0 9rem;
        }

        &Head {
            position: relative;
            width: 100%;
            height: 18rem;
            margin-bottom: 4rem;
            background: #fff;
            border-radius: 5rem;
            @extend %reset;

            @include media(0, $mediaM) {
                height: 23rem;
                margin-bottom: 3rem;
                border-radius: 6rem;
            }

            &::after {
                position: absolute;
                bottom: 3.5rem;
                left: 50%;
                z-index: 2;
                width: 40%;
                height: 0.8rem;
                background: rgba(#250003, 0.2);
                transform: translate(-50%, 0);
                filter: blur(1rem);
                content: "";
            }

            &Box {
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                margin-top: -7rem;
                @extend %reset;

                @include media(0, $mediaM) {
                    margin-top: -9rem;
                }

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: -1;
                    width: 110%;
                    height: 110%;
                    transform: translate(-50%, -50%);
                    content: "";
                    @include bg("../media/prize-form-3-1.svg", contain, center);

                    @include media(0, $mediaM) {
                        width: 120%;
                        height: 120%;
                    }
                }
            }
        }

        &Image {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            margin-top: -2rem;
            object-fit: contain;
            transform: scale(1.35);
            @extend %reset;

            @include media(0, $mediaM) {
                margin-top: -3rem;
                transform: scale(1.5);
            }
        }

        &Title {
            margin-bottom: 1.2rem;
            color: #fff;
            font-size: 2.5rem;
            font-family: "Meloriac";
            letter-spacing: 0.1rem;
            white-space: nowrap;
            text-align: center;
            @extend %reset;

            @include media(0, $mediaM) {
                font-size: 2.7rem;
            }
        }

        &Description {
            color: #fff;
            font-weight: 600;
            font-size: 2.3rem;
            text-transform: uppercase;
            @extend %reset;

            & span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 3.5rem;
                height: 3.5rem;
                font-weight: 600;
                font-size: 2.2rem;
                background: #ff6b00;
                border-radius: 50%;
            }
        }

        &Support {
            position: relative;
            margin-top: 1rem;
            margin-right: 4rem;
            padding: 0.5rem 1rem;
            color: #fff;
            font-weight: 700;
            font-size: 1.6rem;
            white-space: nowrap;
            text-transform: uppercase;
            background: #6bc4e9;
            border-radius: 36rem;
            @extend %reset;

            &Info {
                position: absolute;
                top: 50%;
                left: 100%;
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2.5rem;
                height: 2.5rem;
                margin-left: 0.5rem;
                padding-left: 0.1rem;
                color: #6bc4e9;
                font-weight: 500;
                font-size: 1.7rem;
                font-family: "Geologica";
                text-align: center;
                text-transform: none;
                border: 0.2rem solid #6bc4e9;
                border-radius: 50%;
                transform: translate(0, -50%);
                cursor: pointer;
                transition: $trans;
                @extend %reset;

                &:hover {
                    color: #ff6b00;
                    border-color: #ff6b00;
                }

                &:not(:hover) &Alert {
                    opacity: 0;
                    pointer-events: none;
                }

                &Alert {
                    position: absolute;
                    bottom: -1rem;
                    left: 100%;
                    z-index: 2;
                    width: 17rem;
                    margin-left: 1.5rem;
                    padding: 2.2rem;
                    color: #fff;
                    font-size: 1.75rem;
                    line-height: 1.3;
                    white-space: normal;
                    text-align: left;
                    background: #ff6b00;
                    border-radius: 2rem;
                    box-shadow: 0.8rem 0.8rem 0.8rem rgba(#000, 0.15);
                    transition: $trans;
                    @extend %reset;
                }
            }
        }
    }

    &__btn {
        display: none;
        margin-top: -9rem;
        @extend %arrow;

        @include media(0, $mediaM) {
            display: block;
        }

        &._prev {
            left: 0;
        }

        &._next {
            right: 0;
        }
    }

    &__pagination {
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 3rem;

        @include media(0, $mediaM) {
            display: flex;
        }

        &Item {
            width: 1rem;
            height: 1rem;
            margin: 0 1rem;
            background: #fff;
            border-radius: 50%;
            transition: $trans;

            &:not(._current) {
                opacity: 0.3;
            }
        }
    }
}
