.profileCard {
    position: relative;
    z-index: 1;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 5rem 4rem 4rem 4rem;
    overflow: hidden;
    background: #fff;
    border-radius: 4.3rem;
    @extend %reset;
    @extend %col;

    @include media(0, $mediaM) {
        padding: 3rem 3rem 3rem 3rem;
        border-radius: 3rem;
    }

    &__content {
        align-items: center;
        width: 100%;
        @extend %reset;
        @extend %col;
    }

    &__title {
        margin-bottom: 1rem;
        color: #e5007d;
        font-size: 4rem;
        font-family: "Meloriac";
        text-align: center;
        @extend %reset;

        @include media(0, $mediaM) {
            margin-bottom: 1.5rem;
            font-size: 3.5rem;
        }
    }

    &__subTitle {
        padding: 0.5rem 1.5rem;
        color: #fff;
        font-weight: 500;
        font-size: 1.9rem;
        text-transform: uppercase;
        background: #5c0f8b;
        border-radius: 1rem;

        & span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 3rem;
            height: 3rem;
            margin: 0 0.2rem;
            color: #fff;
            font-weight: 500;
            background: #ff6b00;
            border-radius: 50%;
        }
    }

    &__description {
        margin-top: 1rem;
        color: #5c0f8b;
        font-weight: 500;
        font-size: 1.9rem;
        text-transform: uppercase;
    }

    &__image {
        display: block;
        width: 100%;
        height: 21rem;
        margin: 2.5rem 0;
        object-fit: contain;
        @extend %reset;

        @include media(0, $mediaM) {
            height: 22rem;
            margin: 2.5rem 0 1.5rem 0;
        }
    }

    &__slider {
        position: relative;
        width: 100%;
        margin: 2rem 0 0 0;
        @extend %reset;

        &Arrow {
            position: absolute;
            top: 50%;
            z-index: 2;
            margin-top: -3rem;
            transform: translate(0, -50%);
            @extend %reset;

            &._prev {
                left: 0rem;
            }

            &._next {
                right: 0rem;
            }
        }

        &Items {
            display: inline-flex;
            @extend %reset;
        }

        &Item {
            align-items: center;
            width: 58rem;
            margin-right: 5rem;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                width: 42rem;
            }

            &Tips {
                display: flex;
                align-items: center;
                height: 2rem;
                margin-top: -1rem;
                margin-bottom: 1rem;
                padding: 0.4rem 1rem;
                color: #fff;
                font-weight: 600;
                font-size: 1.6rem;
                white-space: nowrap;
                text-transform: uppercase;
                border-radius: 0.8rem;

                &:not(:empty) {
                    background: #6bc4e9;
                }
            }

            &Head {
                position: relative;
                z-index: 1;
                width: 27rem;
                height: 27rem;
                margin-bottom: 4rem;
                @extend %reset;
                @extend %col;

                &::after {
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    z-index: 2;
                    width: calc(100% - 10rem);
                    height: 0.7rem;
                    margin-top: 0.7rem;
                    background: rgba(#250003, 0.2);
                    transform: translate(-50%, 0);
                    filter: blur(0.7rem);
                    content: "";
                }

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    transform: translate(-50%, -50%);
                    content: "";
                    @include bg("../media/prize-back-1.svg", contain, center);
                }
            }

            &Image {
                position: relative;
                z-index: 2;
                width: 80%;
                height: 80%;
                margin: auto;
                object-fit: contain;
                @extend %reset;
            }

            &Name {
                max-width: 100%;
                color: #5c0f8b;
                font-size: 2.5rem;
                font-family: "Meloriac";
                letter-spacing: 0.1rem;
                text-align: center;
                @extend %reset;
            }
        }
    }

    &__status {
        margin-top: 4rem;
        padding: 1rem 2rem;
        color: #e5007d;
        font-weight: 500;
        font-size: 2.2rem;
        text-transform: uppercase;
        border: 0.2rem solid #e5007d;
        border-radius: 1rem;
        @extend %reset;

        &._success {
            color: #50ae30;
            border-color: #50ae30;
        }
    }

    &__content + &__foot:not(:empty) {
        padding-top: 2.5rem;

        @include media(0, $mediaM) {
            padding-top: 0;
        }
    }

    &__foot {
        align-items: center;
        width: 100%;
        margin-top: auto;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            margin-top: 3rem;
        }
    }

    &__button {
        @extend %reset;
    }

    &__done {
        display: block;
        width: 8rem;
        height: 8rem;
        @extend %reset;
    }
}
