.button {
    position: relative;
    z-index: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 2.2rem 3.2rem 2.1rem 3.2rem;
    overflow: hidden;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    border: 0.4rem solid transparent;
    border-radius: 2.5rem;
    @extend %reset;
    @extend %row;
    @extend %click;
    @include button(0.97);

    @include media(0, $mediaM) {
        padding: 2.5rem 2.5rem 2.3rem 2.5rem;
        border-radius: 2.3rem;
    }

    &._minSize {
        padding: 1rem 1.7rem;
        border-width: 0.3rem;
        border-radius: 1rem;
    }

    &._med {
        padding: 3rem 1rem;

        @include media(0, $mediaM) {
            padding: 2rem 1rem;
            border-width: 0.3rem;
        }
    }

    &._blue {
        background: #6bc4e9;

        &:hover {
            background: #009ee3;
        }
    }

    &._blue &__content {
        color: #fff;
    }

    &._yellow {
        background: #f7a600;
        &:hover {
            background: #ff6b00;
        }
    }

    &._yellow &__content {
        color: #fff;
    }

    &._purple {
        background: #5c0f8b;

        &:hover {
            background: #7201b8;
        }
    }

    &._purple &__content {
        color: #fff;
    }

    &._emptyPurple {
        border-color: #5c0f8b;
        @include loaderColor(#5c0f8b);

        &:hover {
            border-color: #7201b8;
        }
    }

    &._emptyPurple &__content {
        color: #5c0f8b;
    }

    &._emptyPurple:hover &__content {
        color: #7201b8;
    }

    &._white {
        background: #fff;
    }

    &._white &__content {
        color: #5c0f8b;
    }

    &._green{
        background: #1A5632;
    }

    &__content {
        font-weight: 600;
        font-size: 2.3rem;
        line-height: 1.3;
        transition: $trans;
        @extend %reset;

        @include media(0, $mediaM) {
            font-weight: 500;
            font-size: 2.5rem;
            letter-spacing: 0;
        }
    }

    &._minSize &__content {
        font-size: 1.6rem;
    }

    &._loader &__content {
        opacity: 0;
    }

    &._med &__content {
        font-weight: 500;
    }

    &__loader {
        z-index: 100;
        background: inherit;
        @extend %reset;
        @extend %loader;

        &Item {
            width: 3.5rem;
            height: 3.5rem;
            @extend %reset;
            @extend %loaderItem;
            @include loaderColor(#fff);

            @include media(0, $mediaM) {
                width: 3.5rem;
                height: 3.5rem;
            }
        }
    }

    &._pulsation {
        animation: pulsation 1.5s infinite ease-in-out;
        @keyframes pulsation {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.07);
            }

            100% {
                transform: scale(1);
            }
        }
    }

    &._pink {
        background: #e5007d;

        &:hover {
            background: #fc4194;
        }
    }

    &._pink &__content {
        color: #fff;
    }
}
