.footer {
    width: 100%;
    @extend %reset;
    @extend %section;

    &__inner {
        align-items: center;
        width: 86%;
        padding: 6rem 10rem 4rem 10rem;
        background: #145a28;
        border-radius: 4.5rem 4.5rem 0 0;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            width: 100%;
            padding: 8rem 4rem 4rem 4rem;
            border-radius: 3rem 3rem 0 0;
        }
    }

    &__nav {
        align-items: center;
        margin-bottom: 5rem;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            flex-direction: column;
            margin-bottom: 7rem;
        }

        &Link {
            color: #ffd600;
            font-weight: 600;
            font-size: 1.7rem;
            text-transform: uppercase;
            transition: $trans;
            @extend %reset;
            @extend %click;

            @include media(0, $mediaM) {
                font-size: 2rem;
                text-align: center;
            }

            &:hover {
                color: #fff;
            }

            & + & {
                margin-left: 6rem;

                @include media(0, $mediaM) {
                    margin-top: 6rem;
                    margin-left: 0;
                }
            }
        }
    }

    &__content {
        width: 100%;
        margin-bottom: 1.5rem;
        color: #fff;
        font-size: 1.7rem;
        line-height: 1.5;
        text-align: center;

        @include media(0, $mediaM) {
            margin-bottom: 2rem;
            font-size: 2rem;
        }

        & span {
            max-width: 100%;
            color: rgba(#fff, 0.6);
            font-size: 1.7rem;
            line-height: 1.5;
            text-align: center;
            @extend %reset;

            @include media(0, $mediaM) {
                font-size: 2rem;
            }
        }
    }
}
