.profileContent {
    width: 100%;
    padding: 0 0 17rem 0;
    @extend %reset;
    @extend %section;

    @include media(0, $mediaM) {
        padding: 0 0 12rem 0;
    }

    &__inner {
        @extend %reset;
        @extend %inner;

        @include media(0, $mediaM) {
            align-items: center;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
        @extend %reset;
    }

    &__tabs {
        width: 100%;
        margin-bottom: 6rem;
        padding: 0.6rem 0.3rem;
        background: #f7a600;
        border-radius: 2rem;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            margin-bottom: 4.5rem;
            border-radius: 2rem;
        }
    }

    &__tab {
        width: 25%;
        padding: 0 0.3rem;
        @extend %reset;

        @include media(0, $mediaM) {
            flex: 1 auto;
            width: auto;
            padding: 0 0.2rem;
        }

        &Inner {
            align-items: center;
            justify-content: center;
            padding: 2.4rem 2rem 2.2rem 2rem;
            color: #fff;
            font-weight: 500;
            font-size: 3rem;
            font-family: "Meloriac";
            text-transform: uppercase;
            border-radius: 1.7rem;
            transition: $trans;
            @extend %reset;
            @extend %row;
            @extend %click;
            @include button(0.97);

            // &:not([data-current]):hover {
            //     color: #ED40A9;
            // }

            @include media(0, $mediaM) {
                padding-right: 0;
                padding-left: 0;
                font-size: 2.7rem;
                border-radius: 1.3rem;
            }

            &:hover {
                background: rgba(#fff, 0.2);
            }

            &[data-current] {
                color: #5c0f8b;
                background: #fff;
            }
        }
    }

    &__pages {
        position: relative;
        width: 100%;
        transition: $trans;
        @extend %reset;
    }

    &__page {
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        width: 100%;
        transition: $trans;
        @extend %reset;
        @extend %col;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-10rem, 0);
            }

            &._next {
                transform: translate(10rem, 0);
            }
        }
    }

    &__pages._static &__page {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__page {
        &Loader {
            @extend %reset;
            @extend %loader;

            &Item {
                @extend %reset;
                @extend %loaderItem;
            }
        }

        &Head {
            align-items: center;
            max-width: 100%;
            margin-bottom: 5rem;
            transition: $trans;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                margin-bottom: 4rem;
            }

            &._hide {
                opacity: 0;
            }
        }

        &Title {
            color: #fff;
            font-size: 5rem;
            font-family: "Meloriac";
            line-height: 1.3;
            text-align: center;
            @extend %reset;

            @include media(0, $mediaM) {
                font-size: 4rem;
            }
        }

        &Description {
            max-width: 80rem;
            margin-top: 2rem;

            @include media(0, $mediaM) {
                max-width: 90%;
            }
        }

        &Content {
            position: relative;
            width: 100%;
            @extend %reset;
            @extend %col;

            &._top {
                margin-bottom: 3.5rem;

                @include media(0, $mediaM) {
                    margin-bottom: 6rem;
                }
            }
        }

        &Widget {
            width: 100%;
            height: 80rem;
            @extend %reset;

            @include media(0, $mediaM) {
                width: calc(100% + 7vw);
                height: 70rem;
                margin-left: -3.5vw;
            }
        }

        &Button {
            @extend %reset;

            &._parent {
                margin: 0 auto 0 auto;
                white-space: nowrap;

                &:not(._empty) {
                    margin-top: 5rem;
                }
            }
        }
    }

    &__marks {
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        width: 100%;
        transition: $trans;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
    }

    &__pageContent:not(._ready) &__marks {
        opacity: 0;
    }

    &__mark {
        width: calc(50% - 2rem);
        margin-bottom: 4rem;
        @extend %reset;

        @include media(0, $mediaM) {
            width: 100%;
            margin-bottom: 0;

            & + & {
                margin-top: 2rem;
            }
        }
    }

    &__table {
        width: 100%;
        padding: 4rem;
        background: #f7a600;
        border-radius: 6rem;
        @extend %reset;

        @include media(0, $mediaM) {
            padding: 1rem;
            border-radius: 3.2rem;
        }
    }

    &__game {
        position: relative;
        z-index: 1;
        align-items: center;
        width: 100%;
        padding: 1rem 5rem 5rem 5rem;
        overflow: hidden;
        background-color: #ffd600;
        border-radius: 7.5rem;
        @extend %reset;
        @extend %col;
        @include bg("../media/game-LK-back.png", 100% auto, center top);

        @include media(0, $mediaM) {
            padding: 2rem 1.5rem 1.5rem 1.5rem;
            background-color: #ffd600;
            border-radius: 4.5rem 4.5rem 2.5rem 2.5rem;
            @include bg("../media/game-LK-back-mob.png", 100% auto, center top);
        }

        &Logo {
            width: 47rem;
            height: 27rem;
            margin-bottom: 1rem;
            object-fit: contain;
            object-position: center top;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 40rem;
                height: 19.5rem;
                margin-bottom: 3rem;
            }
        }

        &Head {
            align-items: center;
            width: 100%;
            transition: $trans;
            @extend %reset;
            @extend %col;
        }

        &:not(._ready) &Head {
            opacity: 0;
        }

        &Title {
            max-width: 80%;
            margin-bottom: 2rem;
            color: #fff;
            font-size: 4.3rem;
            font-family: "Meloriac";
            line-height: 1.25;
            letter-spacing: 0.2rem;
            text-align: center;
            text-shadow:
                2px 2px 0 #f7a600,
                -2px -2px 0 #f7a600,
                -2px 2px 0 #f7a600,
                2px -2px 0 #f7a600;
            @extend %reset;

            @include media(0, $mediaM) {
                max-width: 100%;
                font-size: 3.1rem;
                text-shadow:
                    3px 3px 0 #f7a600,
                    -3px -3px 0 #f7a600,
                    -3px 3px 0 #f7a600,
                    3px -3px 0 #f7a600;
            }
        }

        &Description {
            max-width: 90%;
            margin-bottom: 4rem;
            color: #5c0f8b;
            font-weight: 500;
            font-size: 1.9rem;
            line-height: 1.3;
            text-align: center;
            text-transform: uppercase;
            @extend %reset;

            @include media(0, $mediaM) {
                max-width: 100%;
                font-size: 1.95rem;
                line-height: 1.45;
            }
        }

        &Button {
            margin-bottom: 5rem;
            @extend %reset;

            @include media(0, $mediaM) {
                margin-bottom: 4rem;
            }
        }

        &Table {
            width: 100%;
            padding: 3.5rem 3.5rem 1rem 3.5rem;
            background: #fff;
            border-radius: 6rem;
            @extend %reset;

            @include media(0, $mediaM) {
                padding: 1.3rem;
                border-radius: 2rem;
            }

            &Row {
                align-items: stretch;
                width: 100%;
                @extend %reset;
                @extend %row;

                &._head {
                    margin-bottom: 1rem;
                    background: #5c0f8b;
                    border-radius: 2.4rem;

                    @include media(0, $mediaM) {
                        height: 7.5rem;
                        border-radius: 1.5rem;
                    }
                }

                &:not(._head):not(:last-child) {
                    border-bottom: 2px solid rgba(#f7a600, 0.4);
                }
            }

            &Col {
                align-items: center;
                justify-content: center;
                width: calc(60% / 2);
                padding: 1.5rem 3rem;
                font-weight: 500;
                font-size: 2rem;
                text-align: center;
                @extend %reset;
                @extend %col;

                @include media(0, $mediaM) {
                    width: calc(63% / 2);
                }

                @include media(0, $mediaM) {
                    padding: 2.5rem 1rem;
                    line-height: 1.3;
                }

                &._login {
                    width: 40%;

                    @include media(0, $mediaM) {
                        width: 37%;
                    }
                }
            }

            &Row:not(._head) &Col._login {
                @include media(0, $mediaM) {
                    word-break: break-all;
                }
            }

            &Row._head &Col {
                padding-top: 2.5rem;
                padding-bottom: 2.5rem;
                color: #fff;
                font-size: 2.2rem;
                font-family: "Meloriac";

                @include media(0, $mediaM) {
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    font-size: 2rem;
                    line-height: 1.2;
                }
            }

            &Count {
                color: #e5007d;
                font-size: 2.2rem;
                font-family: "Meloriac";
                @extend %reset;
            }

            &Action {
                display: block;
                width: 3.5rem;
                height: 3.5rem;
                transition: $trans;
                @extend %reset;
                @extend %click;
                @include button(0.96);
            }
        }
    }

    &__prizes {
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + 2.5rem);
        margin-bottom: -3rem;
        margin-left: -1.25rem;
        transition: $trans;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            width: 100%;
            margin-bottom: 0;
            margin-left: 0;
        }

        &:not(._ready) {
            opacity: 0;
        }

        &Wrapper {
            position: relative;
            z-index: 1;
            width: 100%;
            padding: 3rem;
            overflow: hidden;
            background: #ec5500;
            border-radius: 6rem;
            transition: $trans;
            @extend %reset;

            @include media(0, $mediaM) {
                padding: 2rem;
                border-radius: 4rem;
            }
        }

        &Item {
            width: calc(100% / 3);
            margin-bottom: 3rem;
            padding: 0 1.25rem;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 100%;
                margin-bottom: 0;
                padding: 0;
                & + & {
                    margin-top: 2rem;
                }
            }
        }
    }

    &__prize {
        position: relative;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 2rem 3rem 3rem 3rem;
        background: #fff;
        border-radius: 4rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            padding-top: 3rem;
            border-radius: 2.5rem;
        }

        &Head {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 23rem;
            margin-bottom: 1.2rem;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                margin-bottom: 2rem;
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%);
                content: "";
                @include bg("../media/prize-back-2.svg", contain, center);
            }
        }

        &Image {
            position: relative;
            z-index: 2;
            width: 80%;
            height: 80%;
            margin: auto;
            object-fit: contain;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 80%;
                height: 80%;
            }
        }

        &Count {
            position: absolute;
            top: 2rem;
            right: 2rem;
            align-items: center;
            color: #5c0f8b;
            font-size: 1.9rem;
            font-family: "Meloriac";
            @extend %reset;
            @extend %col;

            &Item {
                align-items: center;
                justify-content: center;
                width: 5.5rem;
                height: 5.5rem;
                margin-bottom: 0.5rem;
                padding-top: 0.3rem;
                color: #f7a600;
                font-size: 3rem;
                font-family: "Meloriac";
                background: #5c0f8b;
                border-radius: 50%;
                @extend %reset;
                @extend %col;
            }
        }

        &Name {
            justify-content: center;
            max-width: 100%;
            min-height: 6rem;
            margin-bottom: 1.5rem;
            color: #5c0f8b;
            font-size: 2.4rem;
            font-family: "Meloriac";
            line-height: 1.2;
            letter-spacing: 0.1rem;
            text-align: center;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                font-size: 2.7rem;
                line-height: 1.3;
            }
        }

        &Status {
            margin-top: auto;
            padding: 1.3rem 1.5rem;
            color: #fff;
            font-weight: 500;
            font-size: 1.9rem;
            text-transform: uppercase;
            border-radius: 1rem;
            @extend %reset;

            @include media(0, $mediaM) {
                padding: 1.4rem 1.8rem;
                font-size: 2.2rem;
            }

            &._SENDING {
                background: #6bc4e9;
            }

            &._SENT {
                background: #ff6b00;
            }

            &._ERROR {
                padding: 0.7rem 1rem;
                font-size: 1.6rem;
                background: #ed6da6;

                @include media(0, $mediaM) {
                    padding: 1rem 1.2rem;
                    font-size: 1.8rem;
                }
            }

            &._DATA_NEEDED,
            &._ACT_REQUIRED {
                background: #5c0f8b;
                cursor: pointer;
            }

            &._GET,
            &._RECEIVED {
                background: #f7a600;
            }
        }

        &Error {
            margin: 1rem 0 -1rem 0;
            color: #e5007d;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.3;
            text-align: center;
            @extend %reset;

            @include media(0, $mediaM) {
                font-size: 1.9rem;
            }
        }
    }
}
