.field {
    width: 100%;
    @extend %reset;

    &._main {
        height: 100%;
    }

    &__support {
        margin-bottom: 1rem;
        color: #5c0f8b;
        font-weight: 500;
        font-size: 2.3rem;
        line-height: 1.3;
        @extend %reset;

        @include media(0, $mediaM) {
            margin-bottom: 0.8rem;
            font-size: 2.3rem;
        }
    }

    &._medium &__support {
        color: #5c0f8b;
    }

    &__box {
        position: relative;
        width: 100%;
        height: 7rem;
        @extend %reset;

        @include media(0, $mediaM) {
            height: 8.5rem;
        }
    }

    &._upload &__box {
        height: auto;

        @include media(0, $mediaM) {
            height: auto;
        }
    }

    &._area &__box {
        height: 24rem;

        @include media(0, $mediaM) {
            height: 29rem;
        }
    }

    &._main &__box {
        height: 100%;
    }

    &__input {
        width: 100%;
        height: 100%;
        padding: 1rem 2rem;
        color: $colorDark;
        font-weight: 500;
        font-size: 2.3rem;
        line-height: 1.3;
        background: #fff;
        border: 0.3rem solid rgba(#ffd600, 0.4);
        border-radius: 2rem;
        @extend %reset;

        @include media(0, $mediaM) {
            font-size: 2.4rem;
            border-width: 0.5rem;
            border-radius: 2.3rem;
        }
    }

    &._center &__input {
        text-align: center;
    }

    &._medium &__input {
        background: #f3f3f3;
        border-width: 0.3rem;
        border-radius: 2rem;
    }

    &._area &__input {
        padding: 2rem;
        resize: none;
    }

    &._main &__input {
        padding: 1rem 2rem;
        font-size: 2rem;
        background: #f3f3f3;
        border-radius: 1.5rem;

        @include media(0, $mediaM) {
            border-width: 0.3rem;
        }
    }

    &__list {
        position: absolute;
        top: 100%;
        z-index: 2;
        width: 100%;
        max-height: 20rem;
        margin-top: 0.5rem;
        overflow-y: auto;
        background: #fff;
        border-radius: 1.5rem;
        box-shadow: 0 0 1.2rem rgba(#61636a, 0.15);
        transition: $trans;
        @extend %reset;

        &:not(._show) {
            transform: translate(0, 1rem);
            opacity: 0;
            pointer-events: none;
        }

        &Inner {
            width: 100%;
            padding: 2rem;
            @extend %reset;
        }

        &Support {
            max-width: 100%;
            margin-bottom: 2.5rem;
            color: $colorDark;
            font-size: 1.9rem;
            @extend %reset;
        }

        &Items {
            width: 100%;
            @extend %reset;
        }

        &Item {
            width: 100%;
            color: $colorSub;
            font-size: 2.3rem;
            line-height: 1.3;
            transition: $trans;
            @extend %reset;
            @extend %click;

            &:hover {
                opacity: 0.5;
            }

            & + & {
                margin-top: 1.5rem;
            }

            & span {
                color: $colorMain;
            }
        }
    }
}
