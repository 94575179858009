.faqHeader {
    padding-bottom: 4rem;
    @extend %reset;
    @extend %section;

    @include media(0, $mediaM) {
        padding-bottom: 2rem;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
    }

    &__head {
        width: 100%;
        margin-bottom: 7rem;
        @extend %reset;

        @include media(0, $mediaM) {
            margin-bottom: 5rem;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        padding: 0 0.5rem;
        transition: $trans;
        counter-reset: cardCounter;
        @extend %reset;

        @include media(0, $mediaM) {
            padding: 0;
        }

        &Inner {
            position: relative;
            width: 100%;
            transition: $trans;
            @extend %reset;
        }
    }

    &__loader {
        @extend %reset;
        @extend %loader;

        &Item {
            @extend %reset;
            @extend %loaderItem;
        }
    }

    &:not(._ready) &__contentInner {
        opacity: 0;
    }

    &__card {
        position: relative;
        z-index: 1;
        width: 100%;
        overflow: hidden;
        background: #1a5632;
        border-radius: 2.7rem;
        box-shadow: 0 0 2rem rgba(#cc2fad, 0.5);
        transition: $trans;
        counter-increment: cardCounter;
        @extend %reset;
        @include button(0.98);

        @include media(0, $mediaM) {
            border-radius: 2.5rem;
        }

        &._active {
            background: #fff;
        }

        &:not(._active):hover {
            background: #206b3e;
        }

        & + & {
            margin-top: 2rem;

            @include media(0, $mediaM) {
                margin-top: 2rem;
            }
        }

        &Head {
            position: relative;
            width: 100%;
            padding: 4rem 10rem 4rem 10rem;
            @extend %reset;
            @extend %col;
            @extend %click;

            @include media(0, $mediaM) {
                justify-content: center;
                min-height: 9rem;
                padding: 1.7rem 7rem 1.7rem 9rem;
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 2.5rem;
                align-items: center;
                justify-content: center;
                width: 5.7rem;
                height: 5.7rem;
                padding-top: 0.2rem;
                color: #1a5632;
                font-weight: 600;
                font-size: 2.9rem;
                font-family: "Meloriac";
                background: #ffd600;
                border-radius: 1.9rem;
                transform: translate(0, -50%);
                transition: $trans;
                content: counter(cardCounter);
                @extend %reset;
                @extend %col;

                @include media(0, $mediaM) {
                    left: 1.7rem;
                    width: 5.8rem;
                    height: 5.8rem;
                    font-size: 2.5rem;
                    border-radius: 1.3rem;
                }
            }

            &Icon {
                position: absolute;
                top: 50%;
                right: 3.5rem;
                z-index: 2;
                width: 2.5rem;
                height: 2.5rem;
                transform: translate(0, -50%);
                transition: $trans;
                @extend %reset;
                @include icon(#50ae30);

                @include media(0, $mediaM) {
                    right: 2.2rem;
                }
            }
        }

        &._active &Head {
            &Icon {
                transform: translate(0, -50%) rotate(180deg);
                @include icon(#a8278e);
            }
        }

        &Title {
            max-width: 100%;
            color: #fff;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 1.3;
            text-transform: uppercase;
            transition: $trans;
            @extend %reset;

            @include media(0, $mediaM) {
                font-size: 2.3rem;
                line-height: 1.2;
            }
        }

        &._active &Title {
            color: #1a5632;
        }

        &:not(._active) &ContentInner {
            transform: translate(0, 1rem);
        }

        &:not(._active) &Content * {
            color: #fff;
        }

        &Content {
            width: 100%;
            height: 0;
            transition: $trans;
            @extend %reset;

            &Inner {
                width: 100%;
                padding: 0rem 6rem 1rem 3.7rem;
                transition: $trans;
                @extend %reset;
                @extend %col;

                @include media(0, $mediaM) {
                    padding: 0rem 3rem 0 1.8rem;
                }

                & p {
                    margin-bottom: 1.7rem;
                    color: $colorDark;
                    font-weight: normal;
                    font-size: 2.23rem;
                    line-height: 1.3;
                    transition: $trans;
                    @extend %reset;
                }

                & b {
                    font-weight: bold;
                    @extend %reset;
                }

                & a {
                    display: inline-block;
                    color: #5c0f8b;
                    font-weight: normal;
                    font-size: 2.23rem;
                    line-height: 1;
                    border-bottom: 1px solid #5c0f8b;
                    transition: $trans;
                    @extend %reset;
                    @extend %click;
                }

                & ol,
                & ul {
                }

                & ol {
                    counter-reset: counterFaq;
                    @extend %reset;
                }

                & ul {
                    @extend %reset;
                }

                & li {
                    position: relative;
                    margin-bottom: 2.5rem;
                    padding-left: 5.7rem;
                    color: $colorDark;
                    font-weight: normal;
                    font-size: 2.23rem;
                    line-height: 1.3;
                    transition: $trans;
                    @extend %reset;
                }

                & ol li {
                    counter-increment: counterFaq;

                    &::before {
                        position: absolute;
                        top: -0.4rem;
                        left: 0;
                        align-items: center;
                        justify-content: center;
                        width: 4.2rem;
                        height: 4.2rem;
                        color: #fff;
                        font-weight: normal;
                        font-weight: 500;
                        font-size: 2.2rem;
                        line-height: 1.3;
                        background: #1a5632;
                        border-radius: 1.5rem;
                        content: counter(counterFaq);
                        @extend %reset;
                        @extend %col;
                    }
                }

                & ul li {
                    padding-left: 2rem;
                    counter-increment: counterFaq;

                    @include media(0, $mediaM) {
                        padding-left: 2rem;
                    }

                    &::before {
                        position: absolute;
                        top: 0;
                        left: 0.3rem;
                        color: #a8278e;
                        font-weight: normal;
                        font-size: 2.23rem;
                        line-height: 1.3;
                        content: "•";
                        @extend %reset;

                        @include media(0, $mediaM) {
                            left: 0.3rem;
                        }
                    }
                }

                & img {
                    max-width: 44rem;
                    margin-bottom: 2rem;

                    @include media(0, $mediaM) {
                        width: 100%;
                    }
                }
            }
        }
    }
}
