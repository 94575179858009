.table {
    width: 100%;
    @extend %reset;

    &__content {
        position: relative;
        width: 100%;
        @extend %reset;

        @include media(0, $mediaM) {
            padding: 0 1rem;
        }

        &Inner {
            position: relative;
            z-index: 2;
            width: 100%;
            overflow: hidden;
            transition: $trans;
            @extend %reset;
            @extend %col;
        }
    }

    &__loader {
        z-index: 10;
        @extend %reset;
        @extend %loader;

        &Item {
            @extend %reset;
            @extend %loaderItem;
            @include loaderColor($colorDark);
        }
    }

    &__empty {
        z-index: 9;
        align-items: center;
        justify-content: center;
        padding: 3rem;
        will-change: transform;
        @extend %reset;
        @extend %loader;

        &:not(._show) {
            transform: scale(1.05);
        }

        &Inner {
            width: 100%;
            @extend %reset;
        }

        &Title {
            max-width: 100%;
            color: $colorDark;
            font-weight: 500;
            font-size: 2.3rem;
            line-height: 1.4;
            text-align: center;
            @extend %reset;
        }
    }

    ._parentCalc &__rowWrapper,
    &__contentInner._static &__rowWrapper {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__rowWrapper:not(._show) &__row {
        opacity: 0;
    }

    &__row {
        align-items: stretch;
        width: 100%;
        transition: $trans;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            justify-content: space-between;
            padding: 2rem 0;
        }

        &Wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: $trans;
            @extend %reset;
        }

        &._head {
            height: 7rem;
            margin-bottom: 1rem;
            background: #5c0f8b;
            border-radius: 2.5rem;
        }

        &Wrapper._last & {
            // border-radius: 0 0 1.5rem 1.5rem;
        }

        &:not(._head) {
        }

        &Inner {
            width: 100%;
            @extend %reset;
            @extend %row;

            & + & {
                margin-top: 1rem;
            }
        }
    }

    &__row._head &__col {
        color: #fff;
        font-family: "Meloriac";
        letter-spacing: 0.25rem;
    }

    &._winners &__row._head,
    &._cheques &__row._head {
        @include media(0, $mediaM) {
            display: none;
        }
    }

    &__row._head &__col {
        @include media(0, $mediaM) {
            padding: 2rem 0;
        }
    }

    &__row:not(._head) &__col {
        padding-top: 2rem;
        padding-bottom: 2rem;
        font-weight: 500;
        font-size: 1.9rem;

        @include media(0, $mediaM) {
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
            font-size: 2.3rem;

            &._head {
                color: #5c0f8b;
                font-weight: 600;
                text-transform: uppercase;
            }
        }

        & * {
            font-weight: 500;
        }
    }

    &__rowWrapper &__row {
        border-bottom: 2px solid transparent;
        transition: $trans;
    }

    &__rowWrapper:not(._last) &__row {
        border-bottom-color: rgba(#fff, 0.4);
    }

    &._cheques &__row._head {
        @include media(0, $mediaM) {
            border-radius: 2rem;
        }
    }

    &._cheques &__row._head &__col {
        @include media(0, $mediaM) {
            padding: 0;
        }

        &Wrapper {
            @include media(0, $mediaM) {
                padding: 2rem 0;
            }
        }
    }

    &__col {
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 2rem 2rem 2rem 2rem;
        font-weight: 400;
        font-size: 2.2rem;
        line-height: 1.3;
        text-align: center;
        text-transform: uppercase;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0;
            font-size: 2.5rem;
            text-align: left;
            text-transform: none;

            &:empty {
                display: none;
            }
        }

        &._head {
            font-weight: 600;
        }

        &Wrapper {
            align-items: stretch;
            font-weight: 400;
            font-size: 2.3rem;
            line-height: 1.3;
            @extend %reset;
            @extend %row;

            @include media(0, $mediaM) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &Inner {
            font-weight: 400;
            line-height: 1.3;
            text-align: center;
            @extend %reset;

            @include media(0, $mediaM) {
                text-align: left;
            }

            & span {
                &._support {
                    color: #de261f;
                }
            }
        }
    }

    &__colWrapper._main &__col {
        width: 100%;
    }

    &__row._head &__col {
        align-items: center;
        font-weight: 600;
    }

    ._winners &__row,
    &._winners &__row {
        @include media(0, $mediaM) {
            flex-direction: column;
        }
    }

    ._winners &__col,
    &._winners &__col {
        @include media(0, $mediaM) {
            &._head {
                width: 45% !important;
            }

            &:not(._head) {
                width: 55% !important;
            }
        }
    }

    ._winners &__col,
    &._winners &__col {
        align-items: center;

        @include media(0, $mediaM) {
            align-items: flex-start;
        }

        &Wrapper {
            &._main {
                width: 25%;

                @include media(0, $mediaM) {
                    width: 27%;
                }
            }

            &._other {
                width: 75%;

                @include media(0, $mediaM) {
                    width: 73%;
                }
            }
        }

        &._email {
            width: 25%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &._phone {
            width: 35%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &._prize {
            width: 40%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }
    }

    ._cheques &__col,
    &._cheques &__col {
        align-items: center;

        @include media(0, $mediaM) {
            align-items: flex-start;
        }

        &Wrapper {
            &._main {
                width: 20%;

                @include media(0, $mediaM) {
                    width: 25%;
                }
            }

            &._other {
                width: 80%;

                @include media(0, $mediaM) {
                    width: 70%;
                }
            }
        }

        &._fd {
            width: 20%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &._status {
            width: 35%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &._result {
            width: 45%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }
    }

    &__col._email &__colInner {
        word-break: break-all;
    }
}
