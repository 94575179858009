.arrow {
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 7rem;
    background: #ff6b00;
    border-radius: 50%;
    transition: $trans;
    @extend %reset;
    @extend %click;
    @extend %col;
    @include button(0.97);

    &:hover {
        background: #5c0f8b;
    }

    &._white {
        background: #fff;

        @include icon(#ff6b00);

        &:hover {
            background: #5c0f8b;

            @include icon(#ff6b00);
        }
    }

    &__icon {
        display: block;
        width: 1.7rem;
        @extend %reset;
    }

    &._prev &__icon {
        margin-left: -0.4rem;
    }

    &._next &__icon {
        margin-left: 0.4rem;
    }
}
