﻿@font-face {
    font-weight: 400;
    font-family: 'Meloriac';
    font-style: normal;
    src: local('Meloriac'), url('../fonts/Meloriac/Meloriac-Regular.ttf');
}

@font-face {
    font-weight: 400;
    font-family: 'Inter';
    font-style: normal;
    src: local('Inter'), url('../fonts/Inter/Inter-Regular.ttf');
}

@font-face {
    font-weight: 600;
    font-family: 'Inter';
    font-style: normal;
    src: local('Inter'), url('../fonts/Inter/Inter-Bold.ttf');
}

@font-face {
    font-weight: 400;
    font-family: 'Geologica';
    font-style: normal;
    src: local('Geologica'), url('../fonts/Geologica/GeologicaRoman-Light.woff2');
}

@font-face {
    font-weight: 500;
    font-family: 'Geologica';
    font-style: normal;
    src: local('Geologica'), url('../fonts/Geologica/GeologicaRoman-Medium.woff2');
}

@font-face {
    font-weight: 600;
    font-family: 'Geologica';
    font-style: normal;
    src: local('Geologica'), url('../fonts/Geologica/GeologicaRoman-Bold.woff2');
}