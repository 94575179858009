.form {
    position: relative;
    z-index: 1;
    align-items: center;
    width: 100%;
    transition: $trans;
    @extend %reset;
    @extend %col;

    &._success {
        height: 30rem !important;
    }

    &__success {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 5rem 13rem;
        background: #ffd600;
        border-radius: 3rem;
        transition: $trans;
        will-change: transform;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            padding: 3rem;
        }

        &:not(._show) {
            transform: scale(1.02);
            opacity: 0;
        }

        &Content {
            color: #5c0f8b;
            font-weight: 500;
            font-size: 2rem;
            line-height: 1.3;
            text-align: center;
            @extend %reset;

            & span {
                display: block;
                margin-bottom: 2rem;
                color: #5c0f8b;
                font-size: 2.4rem;

                &._link {
                    display: inline-block;
                    margin-bottom: 0;
                    font-size: 2rem;
                    border-bottom: 1px solid #5c0f8b;
                    @extend %click;
                }
            }
        }
    }

    &__inner {
        align-items: center;
        width: 100%;
        transition: $trans;
        @extend %reset;
        @extend %col;
    }

    &._success &__inner {
        opacity: 0;
    }

    &__fields {
        width: 100%;
        margin-bottom: 3.5rem;
        @extend %reset;
    }

    &__field {
        width: 100%;
        @extend %reset;

        & + & {
            margin-top: 3.5rem;

            @include media(0, $mediaM) {
                margin-top: 3rem;
            }
        }
    }

    &__field:not(._checkbox) + &__field._checkbox {
        margin-top: 5rem;

        @include media(0, $mediaM) {
            margin-top: 3rem;
        }
    }

    &__required {
        width: 100%;
        margin-bottom: 3rem;
        color: #5c0f8b;
        font-weight: 500;
        font-size: 2rem;
        line-height: 1.3;
        @extend %reset;

        @include media(0, $mediaM) {
            font-size: 2.1rem;
        }
    }

    &__error {
        transition: $trans;
        @extend %reset;

        &._parent {
            max-width: 100%;

            &._static {
                @extend %col;
            }
        }

        &:not(._empty) {
            margin-bottom: 2rem;
        }
    }

    &__button {
        @extend %reset;
    }
}
