.aboutHeader {
    width: 100%;
    padding: 15.5rem 0 0 0;
    @extend %reset;
    @extend %section;
    @include bg("../media/product-back.jpg", 100% auto, center top);

    @include media(0, $mediaM) {
        padding: 11rem 0 0 0;
        @include bg("../media/product-back-mob.jpg", 100% auto, center top);
    }

    &__inner {
        position: relative;
        align-items: center;
        @extend %reset;
        @extend %inner;
    }

    &__content {
        position: relative;
        width: 100%;
        height: 65rem;
        @extend %reset;

        @include media(0, $mediaM) {
            height: 74rem;
        }

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            display: none;
            width: 125rem;
            height: 72rem;
            margin: -0.5rem 0 0 4.5rem;
            transform: translate(-50%, -50%);
            content: "";
            pointer-events: none;
            @include bg("../media/product-back-elements.svg", contain, center);

            @include media(0, $mediaM) {
                width: 100rem;
                height: 55rem;
                margin: 10rem 0 0 6rem;
            }
        }

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            width: 140rem;
            height: 120rem;
            margin: 12rem 0 0 0;
            transform: translate(-50%, -50%);
            content: "";
            pointer-events: none;
            @include bg("../media/stars.svg", contain, center);

            @include media(0, $mediaM) {
                width: 120rem;
                height: 80rem;
                margin: 1rem 0 0 0;
            }
        }
    }

    &__block {
        position: relative;
        z-index: 3;
        justify-content: center;
        width: 100%;
        height: 100%;
        transition: $trans;
        will-change: transform;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            padding-top: 15rem;
        }

        &::before {
            position: absolute;
            top: 1.5rem;
            right: -6.5rem;
            z-index: 2;
            width: 5rem;
            height: 9rem;
            content: "";
            pointer-events: none;
            @include bg("../media/procuct-right-stars.svg", contain, right top);
        }

        &Info {
            position: absolute;
            top: 1rem;
            left: 3rem;
            z-index: 5;
            width: 50rem;
            transition: 0.5s ease-in-out;
            will-change: transform;
            @extend %reset;

            @include media(0, $mediaM) {
                left: 0;
            }

            &:not(._current) {
                opacity: 0;
                pointer-events: none;
            }
        }

        &Name {
            max-width: 100%;
            margin-bottom: 1.7rem;
            color: #fff;
            font-size: 3.15rem;
            font-family: "Meloriac";
            line-height: 1.15;
            transition: 0.7s ease-in-out;
            @extend %reset;

            @include media(0, $mediaM) {
                margin-bottom: 1.5rem;
                font-size: 3.3rem;
                line-height: 1.3;
            }

            &:not(._anim) {
                transform: translate(0, 5rem);
                opacity: 0;
            }

            & b {
                display: block;
                letter-spacing: 0.2rem;
            }
        }

        &Info._JC &Name b {
            color: #ffd600;
        }

        &Info._SD &Name b {
            color: #6bc4e9;
        }

        &Options {
            width: 100%;
            @extend %reset;
        }

        &Option {
            position: relative;
            max-width: 100%;
            padding-left: 2.7rem;
            color: #fff;
            font-weight: 500;
            font-size: 1.9rem;
            transition: 0.7s ease-in-out;
            @extend %reset;

            @include media(0, $mediaM) {
                font-size: 2rem;
            }

            &:not(._anim) {
                transform: translate(0, 5rem);
                opacity: 0;
            }

            & + & {
                margin-top: 1.2rem;

                @include media(0, $mediaM) {
                    margin-top: 1.5rem;
                }
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                color: #fff;
                font-size: 3.5rem;
                transform: translate(0, -50%);
                opacity: 0.6;
                content: "•";
            }
        }

        &._aboutActive &Info {
            @include media(0, $mediaM) {
                transform: scale(0.95);
                opacity: 0;
            }
        }

        &About {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 5;
            width: 44rem;
            padding: 2rem;
            background: rgba(#c31700, 0.4);
            border-radius: 2.8rem;
            transition: $trans;
            @extend %reset;

            @include media(0, $mediaM) {
                z-index: 10;
                width: 100%;
                padding: 1.2rem 6rem 1.2rem 1.2rem;
                border-radius: 1.7rem;
                will-change: transform;

                &:not(._active) {
                    transform: scale(1.05);
                    opacity: 0;
                    pointer-events: none;
                }
            }

            &:not(._current) {
                opacity: 0;
                pointer-events: none;
            }

            &Inner {
                color: #fff;
                font-size: 1.7rem;
                line-height: 1.4;
                @extend %reset;

                @include media(0, $mediaM) {
                    font-weight: 500;
                    font-size: 1.75rem;
                }

                & span {
                    font-family: "Meloriac";
                    text-transform: uppercase;
                }
            }

            &Close {
                position: absolute;
                top: 1.3rem;
                right: 1.3rem;
                z-index: 2;
                width: 3.5rem;
                height: 3.5rem;
            }
        }

        &._aboutActive &MobAbout {
            @include media(0, $mediaM) {
                transform: scale(0.95);
                opacity: 0;
            }
        }

        &MobAbout {
            position: absolute;
            top: 1rem;
            right: 0;
            z-index: 10;
            display: flex;
            align-items: center;
            padding: 0.5rem 0.5rem 0.5rem 1.2rem;
            color: #fff;
            font-weight: 500;
            font-size: 1.9rem;
            text-transform: uppercase;
            background: rgba(#c31700, 0.7);
            border-radius: 36rem;
            transition: $trans;
            will-change: transform;
            @extend %reset;

            &:not(._current) {
                opacity: 0;
                pointer-events: none;
            }

            &Icon {
                display: block;
                width: 2.5rem;
                height: 2.5rem;
                margin-left: 1rem;
                @extend %reset;
                @include bg("../media/info-sostav-mob.svg", contain, right center);
            }
        }

        &Btn {
            position: absolute;
            top: 50%;
            z-index: 2;
            width: 7rem;
            height: 7rem;
            object-fit: contain;
            transform: translate(0, -50%);
            @extend %reset;
            @extend %click;

            @include media(0, $mediaM) {
                display: none;
            }

            &._prev {
                left: 2.5rem;
            }

            &._next {
                right: 2.5rem;
            }
        }

        &ItemsBox {
            position: relative;
            z-index: 1;
            width: 100%;
            padding: 0 16rem;
            @extend %reset;

            @include media(0, $mediaM) {
                padding: 0;
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -2;
                width: 45rem;
                height: 90rem;
                transform: translate(-50%, -50%);
                animation: pulsation 2s infinite ease-in-out;
                content: "";
                pointer-events: none;
                @include bg("../media/pack-glow.png", contain, center);

                @keyframes pulsation {
                    0% {
                        transform: translate(-50%, -50%);
                    }

                    50% {
                        transform: translate(-50%, -50%) scale(1.2);
                    }

                    100% {
                        transform: translate(-50%, -50%);
                    }
                }

                @include media(0, $mediaM) {
                    width: 40rem;
                    height: 70rem;
                }
            }

            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                width: 163rem;
                height: 120rem;
                margin: 14rem 0 0 0;
                transform: translate(-50%, -50%);
                content: "";
                pointer-events: none;
                @include bg("../media/rays.svg", contain, center);

                @include media(0, $mediaM) {
                    width: 140rem;
                    height: 100rem;
                }
            }

            &Inner {
                position: relative;
                width: 100%;
                @extend %reset;
            }
        }

        &Items {
            display: inline-flex;
            @extend %reset;
        }

        &Items._drag &Item:not(._main):not(._active) &ItemInner {
            transform: scale(0.3) !important;
            opacity: 1 !important;

            @include media(0, $mediaM) {
                transform: scale(0.3) !important;
            }
        }

        &Item {
            position: relative;
            width: 12rem;
            margin-right: 10.8rem;
            @extend %reset;

            @include media(0, $mediaM) {
                margin-right: 6rem;
            }

            &._main &Inner {
                transform: translate(0, -3rem) scale(2.1);

                @include media(0, $mediaM) {
                    transform: translate(0, 0) scale(1.6);
                }

                &::after {
                    opacity: 1;
                }
            }

            &:not(._main):not(._active) &Inner {
                opacity: 0;
            }

            &._active._prev:not(._min) &Inner {
                transform: translate(-6rem, 0) scale(0.85);

                @include media(0, $mediaM) {
                    transform: translate(-6.5rem, 0) scale(0.6);
                }
            }

            &._active._next:not(._min) &Inner {
                transform: translate(6rem, 0) scale(0.85);

                @include media(0, $mediaM) {
                    transform: translate(6.5rem, 0) scale(0.6);
                }
            }

            &._min &Inner {
                transform: scale(0.5);
            }

            &Inner {
                position: relative;
                width: 100%;
                transform: scale(0.2);
                transition: 0.5s ease-in-out;
                pointer-events: none;
                will-change: transform;
                @extend %reset;

                &::after {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 2;
                    width: 100%;
                    height: 1.2rem;
                    margin-top: 1.5rem;
                    background: rgba(#9d1c00, 0.3);
                    opacity: 0;
                    filter: blur(0.5rem);
                    transition: 0.5s ease-in-out;
                    content: "";
                }
            }

            &Wrapper {
                width: 100%;
                will-change: transform;
                @extend %reset;
            }

            &._main &Wrapper {
                animation: pulsationItem 2s infinite ease-in-out;
                @keyframes pulsationItem {
                    0% {
                        transform: scale(0.97);
                    }
                    50% {
                        transform: scale(1.03);
                    }
                    100% {
                        transform: scale(0.97);
                    }
                }
            }

            &Image {
                display: block;
                width: 100%;
                will-change: transform;
                @extend %reset;
            }
        }
    }

    &__buttons {
        position: relative;
        // z-index: 1;
        width: 75%;
        height: 20rem;
        margin-top: 3rem;
        margin-bottom: 12.5rem;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            width: 100%;
            height: 15rem;
            margin-bottom: 6rem;
        }

        &::before {
            position: absolute;
            bottom: 100%;
            left: 7rem;
            z-index: 2;
            width: 18rem;
            height: 18rem;
            content: "";
            pointer-events: none;
            @include bg("../media/superjuice.svg", contain, center);

            @include media(0, $mediaM) {
                left: -1rem;
                width: 13rem;
                height: 13rem;
            }
        }

        &::after {
            position: absolute;
            right: 6rem;
            bottom: 100%;
            z-index: 2;
            width: 18rem;
            height: 18rem;
            content: "";
            pointer-events: none;
            @include bg("../media/superdrink.svg", contain, center);

            @include media(0, $mediaM) {
                right: -1rem;
                width: 13rem;
                height: 13rem;
            }
        }

        &Inner {
            position: relative;
            z-index: 3;
            width: 100%;
            height: 100%;
            padding: 0.2rem;
            background: #5c0f8b;
            border: 0.4rem solid rgba(#220000, 0.2);
            border-radius: 4.5rem;
            @extend %reset;
            @extend %row;
            @include media(0, $mediaM) {
                border-radius: 3.5rem;
            }
        }
    }

    &__button {
        width: 50%;
        height: 100%;
        padding: 2.7rem 8rem;
        border: 0.5rem solid transparent;
        border-radius: 4.1rem;
        transition: $trans;
        @extend %reset;
        @extend %click;
        @include button(0.97);

        @include media(0, $mediaM) {
            padding: 1rem 3rem;
            border-width: 0.4rem;
            border-radius: 3.2rem;
        }

        &._current {
            background: #5f339e;
            border-color: #ffd600;
        }

        &:hover {
            background: #703eb8;
        }

        &Image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            @extend %reset;
        }
    }

    &__aboutWrapper {
        position: relative;
        width: 100%;
        @extend %reset;

        &::before {
            position: absolute;
            top: -22rem;
            right: 100%;
            z-index: 2;
            width: 18rem;
            height: 18rem;
            margin-right: 4rem;
            content: "";
            @include bg("../media/procuct-left-lightning.svg", contain, left bottom);
        }
    }

    &__about {
        position: relative;
        z-index: 1;
        align-items: stretch;
        justify-content: center;
        width: 100%;
        margin-bottom: 15rem;
        padding: 7rem 7rem 12.5rem 7rem;
        overflow: hidden;
        background: #ffd600;
        border-radius: 7.5rem;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            flex-direction: column;
            align-items: center;
            padding: 5rem 3rem;
            border-radius: 4.5rem;
        }

        &Block {
            position: relative;
            width: 30rem;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                align-items: center;
                width: 100%;
            }

            &._content {
                align-items: center;
                width: auto;
                margin: 0 5rem;

                @include media(0, $mediaM) {
                    margin: 0 0 2rem 0;
                }
            }

            &._image {
                position: relative;
                z-index: 1;
                padding: 2rem 0 0 0;

                @include media(0, $mediaM) {
                    margin-bottom: 2rem;
                    padding: 0;
                }

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: -1;
                    width: 41rem;
                    height: 41rem;
                    margin: -2rem 0 0 2rem;
                    transform: translate(-50%, -50%);
                    content: "";
                    @extend %reset;
                    @include bg("../media/juice-skate-back.svg", contain, center);

                    @include media(0, $mediaM) {
                        width: 28rem;
                        height: 28rem;
                        margin: -4rem 0 0 0;
                    }
                }
            }

            &._about {
                padding-top: 11rem;
            }

            &Support {
                position: absolute;
                right: -4.5rem;
                bottom: -8.5rem;
                color: #ed6da6;
                font-size: 1.7rem;
                white-space: nowrap;
                text-transform: uppercase;

                @include media(0, $mediaM) {
                    right: 0.5rem;
                    bottom: -2rem;
                    font-weight: 500;
                    font-size: 1.95rem;
                }
            }
        }

        &Image {
            width: 30rem;
            margin: 2rem 0 0 1rem;
            transform: scale(1.2);
            transform-origin: center top;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 24rem;
                margin: 0;
                transform: none;
            }
        }

        &Title {
            max-width: 100%;
            margin-bottom: 5.5rem;
            color: #5c0f8b;
            font-size: 3rem;
            font-family: "Meloriac";
            line-height: 1.4;
            text-align: center;
            @extend %reset;

            @include media(0, $mediaM) {
                margin-bottom: 2.5rem;
                font-size: 2.75rem;
                line-height: 1.3;
            }
        }

        &Preview {
            position: relative;
            width: 34rem;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 26.5rem;
            }

            &::after {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 1.2rem;
                margin-top: 4.5rem;
                background: rgba(#d16400, 0.3);
                filter: blur(1rem);
                transition: $trans;
                content: "";

                @include media(0, $mediaM) {
                    display: none;
                }
            }

            &Image {
                display: block;
                width: 100%;
                @extend %reset;
            }
        }

        &Info {
            position: relative;
            z-index: 1;
            width: 38rem;
            padding: 3.5rem 0 0 4.5rem;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 42rem;
                margin-left: 4rem;
                padding: 4rem 0 16rem 3rem;
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                width: 44rem;
                height: 44rem;
                margin: 1rem 0 0 -3rem;
                transform: translate(-50%, -50%);
                content: "";
                @extend %reset;
                @include bg("../media/AK-text-back.svg", contain, center);

                @include media(0, $mediaM) {
                    width: 50rem;
                    height: 50rem;
                    margin: -7rem 0 0 -5.5rem;
                }
            }

            &Title {
                max-width: 100%;
                margin-bottom: 1.5rem;
                color: #e5007d;
                font-size: 3rem;
                font-family: "Meloriac";
                @extend %reset;

                @include media(0, $mediaM) {
                    margin-bottom: 2rem;
                    font-size: 3.4rem;
                }

                & span {
                    display: inline-block;
                    font-size: 1.8rem;
                    transform: translate(0, -1rem);
                }
            }

            &Description {
                color: #e5007d;
                font-size: 2rem;
                font-family: "Meloriac";
                line-height: 1.4;
                @extend %reset;

                @include media(0, $mediaM) {
                    font-size: 2.35rem;
                    line-height: 1.4;
                }

                & span {
                    color: #1a5632;
                }
            }
        }
    }

    &__footer {
        width: 100%;
        @extend %reset;

        @include media(0, $mediaM) {
            width: calc(100% + 7vw);
        }
    }
}
