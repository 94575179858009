.winnersHeader {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 0 0 12rem 0;
    position: relative;

    &__inner {
        @extend %reset;
        @extend %inner;
        align-items: center;
        position: relative;
        z-index: 3;
    }

    &__head {
        @extend %reset;
        @extend %col;
        width: 100%;
        position: relative;
        z-index: 2;
        align-items: center;
        margin-bottom: 6rem;

        @include media(0, $mediaM) {
            width: 100%;
            margin-bottom: 5.5rem;
        }

        &Title {
            @extend %title;
            margin-bottom: 1.5rem;

            @include media(0, $mediaM) {
                margin-bottom: 2rem;
            }
        }

        &Description {
            @extend %reset;
            font-size: 2.4rem;
            line-height: 1.2;
            font-weight: 500;
            text-align: center;
            color: #fff;
            text-transform: uppercase;

            @include media(0, $mediaM) {
                max-width: 80%;
                font-size: 2.3rem;
                line-height: 1.4;
            }

            &Link {
                @extend %reset;
                @extend %click;
                color: #fff;
                font-weight: 500;
                border-bottom: 1px solid rgba(#fff, .64);
                display: inline-block;
                @include button();
            }
        }

        &Filter {
            @extend %reset;
            @extend %row;
            width: 100%;
            justify-content: space-between;
            align-items: stretch;
            background: #ED6DA6;
            border-radius: 4.5rem;
            padding: 3.5rem 4rem 2rem 4rem;
            margin-bottom: 4.5rem;

            @include media(0, $mediaM) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                border-radius: 3.5rem;
                padding: 1.5rem;
                margin-bottom: 1rem;
            }

            &Block {
                @extend %reset;
                @extend %col;
                width: calc(50% - 1.8rem);
                align-items: center;

                @include media(0, $mediaM) {
                    width: 100%;

                    &+& {
                        margin-top: 2.5rem;
                    }
                }

                &Content {
                    @extend %reset;
                    width: 100%;
                    height: 7rem;
                    position: relative;
                    margin-bottom: .8rem;

                    @include media(0, $mediaM) {
                        height: 7rem;
                        margin-bottom: 1rem;
                    }
                }

                &Support {
                    @extend %reset;
                    max-width: 100%;
                    font-size: 1.93rem;
                    color: #fff;
                    text-align: center;

                    @include media(0, $mediaM) {
                        font-size: 2.2rem;
                        line-height: 1.25;
                        padding-left: 0;
                    }
                }
            }

            &Input {
                @extend %reset;
                width: 100%;
                height: 100%;
                padding: 1.3rem 6.3rem 1rem 2.5rem;
                font-size: 2.35rem;
                font-weight: 500;
                color: $colorDark;
                background: #fff;
                border-radius: 2rem;

                @include media(0, $mediaM) {
                    font-size: 2.3rem;
                    padding-right: 6rem;
                    padding-top: 1.1rem;
                    border-radius: 2rem;
                    padding-left: 2rem;
                }

                &::-webkit-input-placeholder {
                    color: $colorDark;
                }

                &Icon {
                    @extend %reset;
                    position: absolute;
                    top: 50%;
                    right: 2rem;
                    transform: translate(0, -50%);
                    width: 2.5rem;
                    height: 2.5rem;
                    @include icon(#5C0F8B);

                    @include media(0, $mediaM) {
                        right: 2rem;
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }
            }
        }
    }

    &__content {
        @extend %reset;
        width: 100%;
        background: #FFD600;
        border-radius: 7.5rem;
        padding: 6rem 4.5rem 2rem 4.5rem;
        position: relative;
        z-index: 1;
        overflow: hidden;
        margin-bottom: 5rem;

        @include media(0, $mediaM) {
            border-radius: 4.5rem;
            padding: 1.2rem;
        }
    }

    &__table {
        @extend %reset;
        width: 100%;
        position: relative;
        z-index: 1;
    }

    &__button {
        @extend %reset;
        // width: 31rem;
        white-space: nowrap;

        @include media(0, $mediaM) {
            width: 27rem;
        }
    }
}