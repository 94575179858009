.mobileMenu {
    @extend %reset;
    @extend %section;
    width: 100%;
    height: 100%;
    background: #1A5632;
    overflow-y: auto;

    &__inner {
        @extend %reset;
        @extend %inner;
        padding: 12.5rem 0 2.5rem 0;
    }

    &__content {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;
    }

    &__nav {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;
        margin-bottom: 5rem;

        &Item {
            @extend %reset;

            &+& {
                margin-top: 4rem;
            }
        }

        &Link {
            @extend %reset;
            @extend %click;
            font-size: 2.5rem;
            font-weight: 600;
            text-transform: uppercase;
            color: #fff;
        }
    }

    &__buttons {
        @extend %reset;
        width: 28rem;
    }

    &__button {
        @extend %reset;
        width: 100%;
        display: block;

        &+& {
            margin-top: 3.8rem;
        }

        & .button {
            padding: 1.7rem 1rem;
            border-radius: 1.3rem;
        }

        & .button__content {
            font-size: 2.3rem;
        }
    }
}