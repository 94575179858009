.indexHeader {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 142rem;
    padding: 15rem 0 5rem 0;
    overflow: hidden;
    @extend %reset;
    @extend %section;
    @include bg("../media/back-main-1.jpg", cover, center top);

    @include media(0, $mediaM) {
        height: 141rem;
        padding: 11rem 0 4rem 0;
        @include bg("../media/back-main-1-mob.jpg", cover, center 2rem);
    }

    &__383 {
        width: 100%;
        @extend %reset;
    }

    &__inner {
        position: relative;
        @extend %reset;
        @extend %inner;
    }

    &__slogan {
        position: absolute;
        @extend %reset;

        &._1 {
            position: absolute;
            top: -2.5rem;
            left: -0.5rem;
            z-index: 2;
            width: 63rem;
            height: 63rem;

            @include media(0, $mediaM) {
                top: 21rem;
                left: 0.5rem;
                width: 43rem;
                height: 43rem;
                transform: rotate(11deg);
            }

            &::before {
                display: block;
                width: 100%;
                height: 100%;
                content: "";
                @include bg("../media/slogan.svg", contain, left top);
            }

            &::after {
                position: absolute;
                top: 5rem;
                left: -5.5rem;
                width: 9rem;
                height: 9rem;
                content: "";
                @include bg("../media/lighting-1.svg", contain, left top);

                @include media(0, $mediaM) {
                    top: 3rem;
                    left: -3rem;
                    width: 6rem;
                    height: 6rem;
                }
            }
        }

        &._2 {
            top: 30.5rem;
            right: 0.5rem;
            transform: rotate(-7deg);

            @include media(0, $mediaM) {
                top: auto;
                right: auto;
                bottom: 36rem;
                left: 50%;
                display: flex;
                align-items: center;
                transform: translate(-50%, 0);
            }

            &::after {
                position: absolute;
                top: -14rem;
                left: -1rem;
                width: 9rem;
                height: 9rem;
                transform: rotate(5deg);
                content: "";
                @include bg("../media/stars-1.svg", contain, left top);

                @include media(0, $mediaM) {
                    top: -34.5rem;
                    right: -6.5rem;
                    left: auto;
                    width: 7rem;
                    height: 7rem;
                    transform: rotate(1deg);
                }
            }

            & span {
                display: block;
                color: #fff;
                font-size: 3.6rem;
                font-family: "Meloriac";
                letter-spacing: 0.2rem;

                @include media(0, $mediaM) {
                    font-size: 3.2rem;
                    letter-spacing: 0.2rem;
                }

                &:last-child {
                    margin-top: -0.3rem;
                    margin-left: -2.5rem;
                    font-size: 5.6rem;

                    @include media(0, $mediaM) {
                        margin-top: 0;
                        margin-left: 1rem;
                        font-size: 3.2rem;
                    }
                }
            }
        }
    }

    &__buttons {
        position: relative;
        z-index: 10;
        align-items: center;
        margin-left: auto;
        transition: $trans;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            flex-direction: column;
            width: 80%;
            margin: 0 auto;
        }
    }

    &:not(._ready) &__buttons {
        opacity: 0;
    }

    &__button {
        white-space: nowrap;
        @extend %reset;

        @include media(0, $mediaM) {
            width: 100%;

            & .button {
                padding: 2rem;
                border-radius: 2rem;
            }

            & .button__content {
                font-size: 2.2rem;
            }
        }

        &:first-child {
            position: relative;

            &::after {
                position: absolute;
                top: -2.5rem;
                right: 1rem;
                z-index: 2;
                width: 6rem;
                height: 6rem;
                content: "";
                @include bg("../media/stars-btn-1.svg", contain, left top);
            }
        }

        & + & {
            margin-left: 2rem;

            @include media(0, $mediaM) {
                margin-top: 2rem;
                margin-left: 0;
            }
        }
    }

    &__content {
        width: 100%;
        margin-top: 62rem;
        padding: 0 3rem;
        @extend %reset;
        // background: red;

        @include media(0, $mediaM) {
            margin-top: 70rem;
        }
    }

    &__items {
        display: inline-flex;
        @extend %reset;

        @include media(0, $mediaM) {
            margin-left: 3rem;
        }
    }

    &__item {
        width: 42rem;
        margin-right: 2rem;
        padding: 0 4.5rem;
        transition: $trans;
        @extend %reset;

        @include media(0, $mediaM) {
            width: 36rem;
            margin-right: 0rem;
            padding: 0 3.5rem;
        }

        &:not([data-current]):not(._active) {
            opacity: 0;
        }
    }

    &__item[data-current] &__prize {
        transform: translate(0, 7rem) scale(1.25);

        @include media(0, $mediaM) {
            transform: translate(0, 0) scale(1.25);
        }

        &Image {
            transform: translate(0, -10rem) scale(1.1);

            @include media(0, $mediaM) {
                transform: translate(0, 0);
            }
        }
    }

    &__prize {
        position: relative;
        align-items: center;
        width: 100%;
        padding: 3.5rem 1rem;
        background: #5c0f8b;
        border-radius: 6rem;
        transform-origin: center top;
        transition: $trans;
        will-change: transform;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            border-radius: 5rem;
            transform-origin: center;
        }

        &::after {
            position: absolute;
            top: 100%;
            left: 3rem;
            z-index: 2;
            width: calc(100% - 6rem);
            height: 1rem;
            margin-top: 3rem;
            background: rgba(#250003, 0.25);
            filter: blur(1rem);
            content: "";
        }

        &Head {
            width: 100%;
            height: 8.5rem;
            margin-bottom: 2.5rem;
            @extend %reset;

            @include media(0, $mediaM) {
                margin-bottom: 0.5rem;
            }
        }

        &Image {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 20rem;
            object-fit: contain;
            object-position: center bottom;
            transform: translate(0, -9rem);
            transition: $trans;
            @extend %reset;

            @include media(0, $mediaM) {
                transform: translate(0, -11rem) !important;
            }
        }

        &Title {
            margin-bottom: 0.3rem;
            color: #fff;
            font-size: 2.3rem;
            font-family: "Meloriac";
            line-height: 1.4;
            letter-spacing: 0.1rem;
            @extend %reset;

            @include media(0, $mediaM) {
                font-size: 2.1rem;
            }
        }

        &Description {
            color: #fff;
            font-size: 1.9rem;
            font-family: "Geologica";
            line-height: 1.2;
            text-transform: uppercase;
            @extend %reset;

            @include media(0, $mediaM) {
                font-size: 1.8rem;
            }
        }
    }
}
