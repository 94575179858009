.profileHeader {
    width: 100%;
    padding: 0 0 7.5rem 0;
    @extend %reset;
    @extend %section;

    @include media(0, $mediaM) {
        padding-bottom: 6rem;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
    }

    &__head {
        width: 100%;
        margin-bottom: 7rem;
        @extend %reset;

        @include media(0, $mediaM) {
            margin-bottom: 5rem;
        }
    }

    &:not(._ready) &__contentCount {
        opacity: 0;
    }

    &:not(._ready) &__contentButtons {
        opacity: 0;
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
        padding: 5rem 3.5rem 3.5rem 3.5rem;
        background: #fff;
        border-radius: 4.5rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            padding: 3.5rem 2.5rem 2.5rem 2.5rem;
            overflow: hidden;
            border-radius: 3.5rem;
        }

        &Inner {
            position: relative;
            width: 100%;
            @extend %reset;
            @extend %col;
        }

        &Count {
            position: absolute;
            top: 0;
            right: 0;
            align-items: flex-end;
            transition: $trans;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                position: relative;
                align-items: flex-start;
                margin: 6rem 0 0 0;
            }

            &Title {
                margin-bottom: 1.5rem;
                color: #6bc4e9;
                font-size: 2.3rem;
                font-family: "Meloriac";
                transition: $trans;
                @extend %reset;

                @include media(0, $mediaM) {
                    margin-bottom: 3rem;
                    font-size: 2.6rem;
                }

                &Item {
                    display: inline-flex;
                    align-items: center;
                    height: 3rem;
                    margin: 0 0.7rem;
                    @extend %reset;

                    & span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 6.5rem;
                        height: 6.5rem;
                        padding: 0 1rem;
                        color: #fff;
                        font-size: 3.7rem;
                        font-family: "Meloriac";
                        background: #ff6b00;
                        border-radius: 36rem;
                        @extend %reset;

                        @include media(0, $mediaM) {
                            min-width: 7.5rem;
                            height: 7.5rem;
                            font-size: 3.7rem;
                        }
                    }
                }
            }
        }

        &Name {
            align-items: center;
            max-width: 100%;
            margin-bottom: 1rem;
            color: #5c0f8b;
            font-weight: 500;
            font-size: 3rem;
            font-family: "Meloriac";
            text-transform: uppercase;
            @extend %reset;
            @extend %row;

            @include media(0, $mediaM) {
                flex-direction: column;
                align-items: flex-start;
                font-size: 3.5rem;
            }
        }

        &Id {
            margin: -0.3rem 0 0 1.5rem;
            padding: 1rem 1rem 0.8rem 1rem;
            color: #5c0f8b;
            font-size: 1.9rem;
            font-family: "Meloriac";
            background: #ffd600;
            border-radius: 1rem;
            @extend %reset;

            @include media(0, $mediaM) {
                margin: 2rem 0 1rem 0;
                padding: 1.1rem 1.5rem;
                font-size: 2.7rem;
                border-radius: 1.3rem;
            }
        }

        &Buttons {
            width: 100%;
            margin-top: 2.5rem;
            transition: $trans;
            @extend %reset;
            @extend %row;

            @include media(0, $mediaM) {
                flex-direction: column;
                margin-top: 4rem;
            }
        }

        &Button {
            position: relative;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 100%;
            }

            &._game {
                &::before {
                    position: absolute;
                    right: 1.5rem;
                    bottom: 100%;
                    z-index: 2;
                    width: 6rem;
                    height: 4rem;
                    margin-bottom: -2rem;
                    content: "";
                    @include bg("../media/profile-stars.svg", contain, right bottom);
                }
            }

            & + & {
                margin-left: 2rem;

                @include media(0, $mediaM) {
                    margin-top: 2.5rem;
                    margin-left: 0;
                }
            }
        }

        &Link {
            color: #5c0f8b;
            font-weight: 500;
            font-size: 1.7rem;
            text-transform: uppercase;
            border-bottom: 1px solid rgba(#5c0f8b, 0.72);
            @extend %reset;
            @extend %click;

            @include media(0, $mediaM) {
                font-size: 2.3rem;
            }
        }

        &Logout {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 2;
            padding: 1.5rem 2rem;
            color: #fff;
            font-weight: 600;
            font-size: 1.7rem;
            text-transform: uppercase;
            background: #ff6b00;
            border-radius: 1rem;
            transition: $trans;
            @extend %reset;
            @extend %click;

            @include media(0, $mediaM) {
                top: 11.2rem;
                bottom: auto;
                padding: 1.2rem 1.7rem;
                font-size: 2rem;
                border-radius: 1.3rem;
            }

            &:hover {
                background: #f7a600;
            }

            &Loader {
                position: absolute;
                top: 50%;
                right: 100%;
                width: 3rem;
                height: 3rem;
                margin-right: 1.5rem;
                transform: translate(0, -50%);
                transition: $trans;
                @extend %reset;
                @include loaderColor(#ff6b00);

                &:not(._show) {
                    opacity: 0;
                }
            }
        }
    }
}
