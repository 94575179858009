.checkbox {
    width: 100%;
    @extend %reset;
    @extend %col;

    &__inner {
        position: relative;
        display: block;
        min-height: 3.5rem;
        padding-left: 4.5rem;
        will-change: transform;
        @extend %reset;
        @extend %click;
        @include button(0.98);

        @include media(0, $mediaM) {
            padding-left: 5.5rem;
        }
    }

    &__input {
        display: none;
    }

    &__point {
        position: absolute;
        top: -0.3rem;
        left: 0;
        z-index: 1;
        width: 3.5rem;
        height: 3.5rem;
        overflow: hidden;
        background: #fff;
        border: 0.3rem solid rgba(#ffd600, 0.4);
        border-radius: 0.6rem;
        transition: $trans;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            top: 0;
            width: 4rem;
            height: 4rem;
            border-radius: 1.1rem;
            transform: translate(0, 0);
        }

        &Icon {
            display: block;
            width: 2rem;
            height: 2rem;
            margin: auto;
            transform: translate(-3rem, 3rem) rotate(-45deg);
            opacity: 0;
            transition: $trans;
            will-change: transform;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 2.2rem;
                height: 2.2rem;
            }
        }
    }

    &._medium &__point {
        background: #f3f3f3;
    }

    &__input:checked ~ &__point {
        background: #5c0f8b;
        border-color: transparent;
    }

    &__input:checked ~ &__point &__pointIcon {
        transform: none;
        opacity: 1;
    }

    &__content {
        color: #5c0f8b;
        font-weight: 400;
        font-size: 2.1rem;
        line-height: 1.3;
        @extend %reset;

        @include media(0, $mediaM) {
            padding-top: 0.4rem;
            font-size: 2.2rem;
        }

        & a {
            display: inline-block;
            color: #5c0f8b;
            font-weight: 400;
            border-bottom: 1px solid rgba(#5c0f8b, 0.72);
            transition: $trans;
            @extend %reset;
            @extend %click;

            @include media(0, $mediaM) {
                display: inline;
            }
            &:hover {
                color: #7201b8;
            }
        }
    }

    &._bold &__content {
        font-weight: 500;
    }

    &._medium &__content {
        color: $colorDark;

        & a {
            color: $colorDark;
            border-bottom: 1px solid rgba($colorDark, 0.72);
        }
    }
}
