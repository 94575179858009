.indexMainPrizes {
    width: 100%;
    padding: 9rem 0 0 0;
    @extend %reset;
    @extend %section;
    @include bg("../media/back-main-5.jpg", cover, center -13rem);

    @include media(0, $mediaM) {
        padding: 8rem 0 0 0;
        @include bg("../media/back-main-5-mob.jpg", 100% auto, center -7rem);
    }

    &__inner {
        align-items: center;
        transition: $trans;
        @extend %reset;
        @extend %inner;
    }

    &:not(._ready) &__inner {
        opacity: 0;
    }

    &__head {
        align-items: center;
        margin-bottom: 12rem;
        @extend %reset;
        @extend %col;

        &Title {
            max-width: 100%;
            margin-bottom: 1.8rem;
            @extend %reset;

            @include media(0, $mediaM) {
                width: calc(100% + 2rem);
                max-width: none;
                margin-bottom: 2.5rem;
                text-align: center;
            }
        }

        &Description {
            max-width: 100%;
            color: #fff;
            font-weight: 500;
            font-size: 2.4rem;
            text-transform: uppercase;
            @extend %reset;

            @include media(0, $mediaM) {
                white-space: nowrap;
                text-align: center;
            }
        }
    }

    &__content {
        position: relative;
        width: 100%;
        margin-bottom: 15.5rem;
        @extend %reset;

        @include media(0, $mediaM) {
            margin-bottom: 6rem;
            padding: 0 3rem;

            &::after {
                position: absolute;
                top: -10rem;
                right: -4rem;
                z-index: 2;
                width: 18rem;
                height: 18rem;
                content: "";
                @include bg("../media/fin-screen-element-3-mob.svg", contain, right top);
            }

            &::before {
                position: absolute;
                top: -7rem;
                left: 4rem;
                z-index: 2;
                width: 4.5rem;
                height: 4.5rem;
                content: "";
                @include bg("../media/fin-screen-element-1-mob.svg", contain, left top);
            }
        }
    }

    &__btn {
        display: none;
        margin-top: -4rem;
        @extend %arrow;

        @include media(0, $mediaM) {
            display: block;
        }

        &._prev {
            left: 0;
        }

        &._next {
            right: 0;
        }
    }

    &__pagination {
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 7rem;

        @include media(0, $mediaM) {
            display: flex;
        }

        &Item {
            width: 1rem;
            height: 1rem;
            margin: 0 1rem;
            background: #fff;
            border-radius: 50%;
            transition: $trans;

            &:not(._current) {
                opacity: 0.3;
            }
        }
    }

    &__items {
        align-items: stretch;
        justify-content: center;
        width: 100%;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            display: inline-flex;
            align-items: stretch;
            justify-content: flex-start;
            width: auto;
        }
    }

    &__item {
        position: relative;
        z-index: 1;
        align-items: center;
        width: 40rem;
        margin: 0 3.5rem;
        padding: 5rem 5rem 2rem 5rem;
        background: #5c0f8b;
        border-radius: 8rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            width: calc(100vw - 10rem);
            margin: 0;
            margin-right: 6rem;
            padding-bottom: 3rem;
            border-radius: 6rem;
        }

        &::after {
            position: absolute;
            top: 100%;
            left: 2.5rem;
            z-index: 2;
            width: calc(100% - 5rem);
            height: 2.3rem;
            margin-top: 5.5rem;
            background: rgba(#250003, 0.15);
            filter: blur(1rem);
            content: "";

            @include media(0, $mediaM) {
                margin-top: 2.5rem;
            }
        }

        &Image {
            width: 100%;
            height: 26rem;
            margin-top: -12rem;
            margin-bottom: 0.7rem;
            object-fit: contain;
            @extend %reset;

            @include media(0, $mediaM) {
                margin-bottom: 0;
            }
        }

        &Name {
            max-width: 100%;
            color: #fff;
            font-size: 3.1rem;
            font-family: "Meloriac";
            line-height: 1.2;
            letter-spacing: 0.1rem;
            text-align: center;
            @extend %reset;
        }
    }

    &__app {
        position: relative;
        width: 100%;
        margin-top: 20rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            margin-top: 23rem;
        }

        &Phone {
            position: absolute;
            bottom: -0.5rem;
            left: 29.5rem;
            z-index: 1;
            width: 27rem;
            @extend %reset;

            @include media(0, $mediaM) {
                right: 8.5rem;
                bottom: 4rem;
                left: auto;
                width: 24rem;
            }

            &::after {
                position: absolute;
                top: 6rem;
                right: 100%;
                z-index: 2;
                display: none;
                width: 9rem;
                height: 9rem;
                margin-right: 3rem;
                content: "";
                @include bg("../media/fin-screen-element-4.svg", contain, center);
            }

            &::before {
                position: absolute;
                top: 2rem;
                left: 2rem;
                z-index: -1;
                width: 100%;
                height: 100%;
                background: rgba(#000, 0.1);
                border-radius: 3rem;
                content: "";
                @extend %reset;
            }

            &Image {
                position: relative;
                z-index: 2;
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
                border: 1rem solid #e6e6e6;
                border-radius: 3rem;
                @extend %reset;

                @include media(0, $mediaM) {
                    border-radius: 2.5rem;
                }
            }
        }

        &Box {
            position: relative;
            z-index: 1;
            width: 43.5rem;
            margin: 0 26.5rem 0 auto;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 39rem;
                margin: 0 5rem 0 auto;
            }

            &::after {
                position: absolute;
                right: 13.5rem;
                bottom: 100%;
                z-index: 2;
                width: 11rem;
                height: 11rem;
                margin-bottom: 9rem;
                content: "";
                @include bg("../media/fin-screen-element-5.svg", contain, center);

                @include media(0, $mediaM) {
                    right: auto;
                    left: -9rem;
                    width: 22rem;
                    height: 22rem;
                    margin-bottom: 6rem;
                    @include bg("../media/fin-screen-element-4-mob.svg", contain, center);
                }
            }

            &::before {
                position: absolute;
                top: 2rem;
                left: 2rem;
                z-index: -1;
                width: 100%;
                height: 100%;
                background: #fff;
                border-radius: 4.5rem;
                content: "";

                @include media(0, $mediaM) {
                    top: 1.5rem;
                    left: 1.5rem;
                    border-radius: 4rem;
                }
            }
        }

        &Qr {
            position: absolute;
            bottom: 100%;
            left: -4rem;
            z-index: 2;
            width: 21rem;
            height: 21rem;
            margin-bottom: -3.5rem;
            padding: 2.5rem;
            background: #f8d000;
            border-radius: 4.5rem;
            @extend %reset;

            @include media(0, $mediaM) {
                display: none;
            }

            &Image {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &Content {
            position: relative;
            width: 100%;
            padding: 5.5rem 15rem 5.5rem 4rem;
            color: #fff;
            font-size: 3rem;
            font-family: "Meloriac";
            line-height: 1.25;
            background: #e5007d;
            border-radius: 4.5rem;
            @extend %reset;

            @include media(0, $mediaM) {
                padding: 5rem 15rem 4.5rem 3.5rem;
                font-size: 2.8rem;
                border-radius: 4rem;
            }

            &::before {
                position: absolute;
                top: 0;
                right: 2.5rem;
                z-index: 2;
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 7rem;
                height: 7rem;
                padding-left: 0.3rem;
                color: #5c0f8b;
                font-weight: 600;
                font-size: 2.4rem;
                font-family: "Geologica";
                text-align: center;
                background: #fff;
                border: 0.6rem solid #e6e6e6;
                border-radius: 50%;
                transform: translate(0, -50%);
                content: "18+";
                @extend %reset;

                @include media(0, $mediaM) {
                    right: auto;
                    left: 3rem;
                    width: 7rem;
                    height: 7rem;
                }
            }

            &::after {
                position: absolute;
                right: 3.5rem;
                bottom: 3.5rem;
                z-index: 2;
                width: 9rem;
                height: 9rem;
                content: "";
                @include bg("../../media/mob-app-icon.png", contain, center);

                @include media(0, $mediaM) {
                    top: 50%;
                    right: 3rem;
                    bottom: auto;
                    width: 8rem;
                    height: 8rem;
                    transform: translate(0, -50%);
                }
            }
        }
    }

    &__button {
        position: relative;
        width: 80%;
        margin-top: 7rem;
        @extend %reset;

        &::after {
            position: absolute;
            top: -2.5rem;
            right: 1rem;
            z-index: 2;
            width: 6rem;
            height: 6rem;
            content: "";
            @include bg("../media/stars-btn-3-mob.svg", contain, left top);
        }
    }

    &__footer {
        width: 100%;
        margin-top: 13rem;
        @extend %reset;

        @include media(0, $mediaM) {
            width: calc(100% + 7vw);
        }
    }
}
