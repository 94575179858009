.indexPrizes {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 8.5rem 0 14rem 0;
    border-radius: 8rem 8rem 0 0;
    background: #5C0F8B;

    @include media(0, $mediaM) {
        border-radius: 4.5rem 4.5rem 0 0;
        padding: 6rem 0 19rem 0;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
        align-items: center;
        transition: $trans;
    }

    &:not(._ready) &__inner {
        opacity: 0;
    }

    &__title {
        @extend %reset;
        margin-bottom: 6rem;

        @include media(0, $mediaM) {
            text-align: center;
            margin-bottom: 7rem;
        }
    }

    &__items {
        @extend %reset;
        @extend %row;
        width: 100%;
        justify-content: space-between;
        padding-left: 5rem;
        counter-reset: counterItem;

        @include media(0, $mediaM) {
            flex-direction: column;
            padding-left: 0;
            align-items: center;
            padding: 0 5rem 0 6rem;
        }
    }

    &__item {
        @extend %reset;
        width: 22%;
        counter-increment: counterItem;

        @include media(0, $mediaM) {
            width: 100%;

            &+& {
                margin-top: 4rem;
            }
        }

        &:nth-child(1) &Content {
            padding-left: 4rem;

            @include media(0, $mediaM) {
                padding-left: 0;
            }
        }

        &:nth-child(2) &Content {
            padding-left: 2rem;

            @include media(0, $mediaM) {
                padding-left: 0;
            }
        }

        &:nth-child(3) &Content {
            padding-left: 1rem;

            @include media(0, $mediaM) {
                padding-left: 0;
            }
        }

        &:nth-child(1) &Head::before {
            left: -3.5rem;

            @include media(0, $mediaM) {
                left: 3rem;
            }
        }

        &:nth-child(2) &Head::before {
            left: -4rem;

            @include media(0, $mediaM) {
                left: 2.5rem;
            }
        }

        &:nth-child(3) &Head::before {
            left: -5rem;

            @include media(0, $mediaM) {
                left: 2.5rem;
            }
        }

        &Head {
            @extend %reset;
            width: 100%;
            height: 24rem;
            position: relative;
            margin-bottom: 1rem;

            @include media(0, $mediaM) {
                height: 23rem;
                margin-bottom: 0rem;
                margin-left: 2rem;
            }

            &::before {
                content: counter(counterItem);
                font-family: 'Meloriac';
                color: rgba(#fff, .1);
                position: absolute;
                z-index: 1;
                top: -1rem;
                left: -6rem;
                font-size: 24rem;

                @include media(0, $mediaM) {
                    left: 1rem;
                    font-size: 22rem;
                }
            }
        }

        &Image {
            @extend %reset;
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: relative;
            z-index: 2;
        }

        &Content {
            @extend %reset;
            width: 100%;

            @include media(0, $mediaM) {
                padding-left: 4.5rem !important;
            }
        }

        &Title {
            @extend %reset;
            max-width: 100%;
            font-size: 2.9rem;
            letter-spacing: .15rem;
            line-height: 1.4;
            font-family: 'Meloriac';
            color: #fff;
            text-transform: uppercase;
            margin-bottom: 0.5rem;

            @include media(0, $mediaM) {
                font-size: 3.1rem;
                margin-bottom: 1rem;
            }
        }

        &:nth-child(1) &Description span {
            @extend %reset;
            max-width: 100%;
            color: #FFD600;
            font-size: 2rem;
            font-weight: 600;
            line-height: 1.2;
            display: inline-block;
            margin-top: .7rem;

            @include media(0, $mediaM) {
                max-width: none;
                width: calc(100% + 10rem);
                font-weight: 500;
                font-size: 2.3rem;
                line-height: 1.3;
            }
        }

        &:nth-child(2) &Description span {
            width: 3rem;
            height: 3rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: #FF6B00;
            border-radius: 50%;
            font-weight: 700;
            color: #fff;
        }

        &Description {
            @extend %reset;
            max-width: 100%;
            font-size: 2.4rem;
            line-height: 1.4;
            color: #fff;

            @include media(0, $mediaM) {
                font-size: 2.5rem;
            }
        }
    }
}